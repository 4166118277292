import React, { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import SettingsPanelSearch from "./SettingsPanelSearch";
import SettingsPanelTabs from "./SettingsPanelTabs";
import SettingsPanelItem from "./SettingsPanelItem";
import SettingsPanelReset from "./SettingsPanelReset";
import { getActiveForTab } from "./SettingsPanelUtil";
import { SettingsPanelItemProps, SettingsPanelProps } from "./types.d";

function SettingsPanel(props: SettingsPanelProps) {
  const [, setState] = useState();

  // Component didUpdate.
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  function propSorter(a: SettingsPanelItemProps, b: SettingsPanelItemProps) {
    const [activeList] = getActiveForTab(props);

    if (activeList.has(a.value) === activeList.has(b.value)) {
      return a.label < b.label ? -1 : 1;
    } else {
      return activeList.has(a.value) > activeList.has(b.value) ? -1 : 1;
    }
  }

  function allItems(): SettingsPanelItemProps[] {
    const items = Array.from(props.tabDataFiltered[props.activeTab]);

    if (props.activeTab === "verbs") {
     return items.sort(propSorter);
    }
    // else
    return items;
  }

  return (
    <nav className="panel">
      <p className="panel-heading">{props.heading}</p>
      <SettingsPanelSearch
        activeTab={props.activeTab}
        tabData={props.tabData}
        tabDataFiltered={props.tabDataFiltered}
        setTabDataFiltered={props.setTabDataFiltered}
        updateParent={setState}
      />
      <SettingsPanelTabs
        activeTab={props.activeTab}
        setActiveTab={props.setActiveTab}
        updateParent={setState}
      />
      <div className="Content">
        {allItems().map((value) => {
          return (
            <SettingsPanelItem
              id={value.id}
              key={value.id}
              tab={value.tab}
              icon={value.icon}
              label={value.label}
              value={value.value}
              activeTab={props.activeTab}
              activeVerbs={props.activeVerbs}
              setActiveVerbs={props.setActiveVerbs}
              activeTenses={props.activeTenses}
              setActiveTenses={props.setActiveTenses}
              activePronouns={props.activePronouns}
              setActivePronouns={props.setActivePronouns}
              updateParent={setState}
            />
          );
        })}
      </div>
      <SettingsPanelReset
        activeTab={props.activeTab}
        activeVerbs={props.activeVerbs}
        setActiveVerbs={props.setActiveVerbs}
        activeTenses={props.activeTenses}
        setActiveTenses={props.setActiveTenses}
        activePronouns={props.activePronouns}
        setActivePronouns={props.setActivePronouns}
        updateParent={setState}
      />
    </nav>
  );
}

export default SettingsPanel;

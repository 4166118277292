import React from "react";
import { SettingsPanelResetProps } from "./types.d";
import { getActiveForTab } from "./SettingsPanelUtil";
import {
  initActiveVerbs,
  initActiveTenses,
  initActivePronouns,
} from "./SettingsPanelUtil";

function SettingsPanelReset(props: SettingsPanelResetProps) {
  function reset() {
    const [, setActiveList] = getActiveForTab(props);
    switch (props.activeTab) {
      case "verbs":
        setActiveList(initActiveVerbs());
        break;
      case "tenses":
        setActiveList(initActiveTenses());
        break;
      case "pronouns":
        setActiveList(initActivePronouns());
        break;
    }

    if (props.updateParent) {
      props.updateParent({});
    }
  }

  return (
    <div className="panel-block">
      <button
        className="button is-link is-outlined is-fullwidth"
        onClick={reset}
      >
        Reset filters
      </button>
    </div>
  );
}

export default SettingsPanelReset;

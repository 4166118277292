import React, { useRef, useEffect, useState } from "react";
import { CurrentPos, UserInputProps, VerbRecords, TenseItems, PronounKey, SP_PRONOUN_KEYS, TENSE_KEYS } from "./types.d";
import { pronounOrder, tenseOrder, fmtTense, isPronounKey, isTenseKey, } from "./Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThLarge,
  faPlay,
  faStop,
} from "@fortawesome/free-solid-svg-icons";

interface ButtonState {
  style: "is-info" | "is-success" | "is-danger";
  mode: "Submit" | "Next";
}

const UserInput = (props: UserInputProps) => {
  const defaultButtonState: ButtonState = {
    style: "is-info",
    mode: "Submit",
  };

  const [autoPlay, setAutoPlay] = useState<boolean>(false);

  const [audioFile, setAudioFile] = useState("");

  const audioRef = useRef<HTMLAudioElement>(null);

  const [buttonState, setButtonState] = useState<ButtonState>(
    defaultButtonState
  );

  const [buttonChoice, setButtonChoice] = useState<string[]>([]);

  const textInput = useRef<HTMLInputElement>(null);

  const accents = ["á", "é", "í", "ó", "ú", "ü", "ñ"];

  // Similar to componentDidMount and componentDidUpdate.
  // Second argument prevents an infinite re-render loop from happening.
  useEffect(() => {
    currentConjugation(true);
  }, []);

  function toggleInputMode() {
    const newMode: ButtonState["mode"] = buttonState.mode === "Submit" ? "Next" : "Submit";
    setButtonState((bs) => Object.assign(bs, { mode: newMode }));
  }

  function toggleAutoPlay() {
    setAutoPlay((ap) => {
      ap = !ap;
      if (ap) {
        submitAnswer('', true);
      } else {
        stopAudio();
      }
      return ap;
    });
  }

  function isValid(needle: string, haystack: string): boolean {
    return haystack.slice(0, needle.length) === needle;
  }

  function sortVerbs(): string[] {
    let verbs = Array.from(props.activeVerbs);
    return verbs.sort((a, b) => (a > b ? 1 : -1));
  }

  function nextTense(): boolean {
    // without anything selected, there's nothing to do
    if (props.activeTenses.size === 0) {
      return false;
    }

    let didRollOver = false;
    let tenses: string[] = tenseOrder;
    tenses = tenses.filter((item) => props.activeTenses.has(item));
    let newPos: CurrentPos;

    // if we are at the end, go back to index 0
    if (props.currentPos.tense === tenses[tenses.length - 1]) {
      newPos = Object.assign(props.currentPos, { tense: tenses[0] });
      didRollOver = true;
      // otherwise, increment the index by 1
    } else {
      const currentIdx: number = tenses.indexOf(props.currentPos.tense);
      newPos = Object.assign(props.currentPos, {
        tense: tenses[currentIdx + 1],
      });
    }
    props.setCurrentPos(newPos);
    return didRollOver;
  }

  function nextPronoun(): boolean {
    // If the current verb was removed, jump to the next.
    if (props.activeVerbs.size > 0 && !props.activeVerbs.has(props.currentPos.verb)) {
      nextVerb();
    }

    // If the current tense was removed, jump to the next.
    if (props.activeTenses.size > 0 && !props.activeTenses.has(props.currentPos.tense)) {
      nextTense();
    }

    // without anything selected, there's nothing to do
    if (props.activePronouns.size === 0) {
      return false;
    }

    let didRollOver = false;
    let pronouns: string[] = pronounOrder.map((e) => e[0]);
    pronouns = pronouns.filter((item) => props.activePronouns.has(item));
    let newPos: CurrentPos;

    // if we are at the end, go back to index 0
    if (props.currentPos.pronoun === pronouns[pronouns.length - 1]) {
      newPos = Object.assign(props.currentPos, { pronoun: pronouns[0] });
      didRollOver = true;
      // otherwise, increment the index by 1
    } else {
      const currentIdx: number = pronouns.indexOf(props.currentPos.pronoun);
      newPos = Object.assign(props.currentPos, {
        pronoun: pronouns[currentIdx + 1],
      });
    }
    props.setStats((stats) =>
      Object.assign(stats, { reviewing: reviewCount() })
    );
    props.setCurrentPos(newPos);
    return didRollOver;
  }

  function reviewCount(): number {
    let reviewing = 0;
    for (let verb of Array.from(props.activeVerbs)) {
      for (let tense of Array.from(props.activeTenses)) {
        for (let pronoun of Array.from(props.activePronouns)) {
          let currentVerb = props.verbs[verb];
          if (isTenseKey(tense) && currentVerb[tense]) {
            let entry = currentVerb[tense];
            if (isPronounKey(pronoun) && entry[pronoun]) {
              reviewing++;
            }
          }
        }
      }
    }
    return reviewing;
  }

  function nextVerb() {
    // without anything selected, there's nothing to do
    if (props.activeVerbs.size === 0) {
      return false;
    }

    let didRollOver = false;
    // This is already constrained to active verbs.
    let verbs: string[] = sortVerbs();
    let newPos: CurrentPos;

    // if we are at the end or the current verb is removed, go back to index 0
    if (props.currentPos.verb === verbs[verbs.length - 1]) {
      const newVerb = verbs[0];
      const examples = props.verbs[newVerb].examples;
      newPos = Object.assign(props.currentPos, {
        verb: newVerb,
        phrases: examples,
      });
      didRollOver = true;
      // otherwise, increment the index by 1
    } else {
      const currentIdx: number = verbs.indexOf(props.currentPos.verb);
      const newVerb = verbs[currentIdx + 1];
      const examples = props.verbs[newVerb].examples;
      newPos = Object.assign(props.currentPos, {
        verb: newVerb,
        phrases: examples,
      });
    }
    props.setCurrentPos(newPos);
    return didRollOver;
  }

  function nextConjugation() {
    // If there is at least one valid combo and the current
    // combo is not valid, keep looking.
    do {
      nextPronoun() && nextTense() && nextVerb();
    } while (reviewCount() > 0 && !validConjugation());
  }

  function validConjugation() {
    const verb = props.currentPos.verb;
    const verbs = props.verbs;
    const tense = props.currentPos.tense;
    const pronoun = props.currentPos.pronoun;
    return verbs[verb] && verbs[verb][tense] && verbs[verb][tense][pronoun];
  }

  function stopAudio() {
    if (audioRef.current) {
      audioRef.current.pause();
    }
  }

  function finishAudio() {
    if (autoPlay) {
      setTimeout(submitAnswer, 1000);
    } else {
      stopAudio();
    }
  }

  function playAudio(verbEntry: TenseItems, pronoun: PronounKey) {
    const audioKey = `audio_${pronoun}`;

    if (isPronounKey(audioKey) && verbEntry[audioKey]) {
      if (audioRef.current) {
        let file = `./audio/${verbEntry[audioKey]}.mp3`;
        audioRef.current.pause();
        audioRef.current.setAttribute("src", file);
        audioRef.current.load();
        let playPromise = audioRef.current.play();
        setAudioFile(file);

        if (playPromise !== undefined) {
          playPromise.then(() => {}).catch((err) => console.log(err));
        }
      }
    }
  }

  function playCurrentAudio() {
    if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.setAttribute("src", audioFile);
        audioRef.current.load();
        let playPromise = audioRef.current.play();

        if (playPromise !== undefined) {
          playPromise.then(() => {}).catch((err) => console.log(err));
        }
    }

    props.updateParent({});
  }

  function currentRecord() {
    const verbs: VerbRecords = props.verbs;
    return {
      currentVerb: verbs[props.currentPos.verb],
      currentTense: props.currentPos.tense,
      currentPronoun: props.currentPos.pronoun,
    };
  }

  function aRand<T>(arr: T[]): T[] {
    return [...arr].sort(() => 0.5 - Math.random());
  }

  function randVerbs() {
    const { currentVerb, currentTense, currentPronoun } = currentRecord();
    // Take all available tenses but put exclude the current tense.
    const availTense = TENSE_KEYS.filter(elem => elem !== currentTense && !elem.includes('continuous'));
    // Take all but the current pronoun and call that available.
    const availPron = SP_PRONOUN_KEYS.filter(elem => elem !== currentPronoun);
    // Shuffle the array of available pronouns.
    const shuffled = aRand(availPron);
    const chosen = [ currentVerb[currentTense][currentPronoun] ];
    while (chosen.length < 4 && shuffled.length > 0) {
      let tmpPronoun = shuffled.pop() as string;
      let tmpRec = currentVerb[currentTense][tmpPronoun];

      if (tmpRec)  {
        chosen.push(tmpRec);
      } else {
        // Cycle through all the available tenses.
        for (let tmpTense of aRand(availTense)) {
          tmpRec = currentVerb[tmpTense][tmpPronoun];

          if (tmpRec) {
            // Prevent duplicates.
            if (!chosen.includes(tmpRec)) {
              chosen.push(tmpRec);
            }

            if (chosen.length === 4) {
              break;
            }
          }
        }
      }
    }
    let result = aRand(chosen);
    setButtonChoice(result);
    console.log(result);
  }

  function getPronounPair(currentPronoun: PronounKey): {en: PronounKey, es: PronounKey} {
      const [pronounRec] = pronounOrder.filter((item) => item[0] === currentPronoun);
      const [es, en] = pronounRec;
      return {es, en}
  }

  function currentConjugation(init: boolean) {
    const { currentVerb, currentTense, currentPronoun } = currentRecord();

    if (isTenseKey(currentTense) && isPronounKey(currentPronoun)) {
      const verbEntry: TenseItems = currentVerb[currentTense];
      const { en } = getPronounPair(currentPronoun);

      props.setAnswer(verbEntry[currentPronoun]);
      // User must interact with app before audio can play
      // because of browser restrictions.
      if (!init && props.activeAudio) {
        playAudio(verbEntry, en);
      }

      if (currentTense === "imperative_affirmative" || currentTense === "imperative_negative") {
        props.setPlaceHolder(`${verbEntry[en]} (${fmtTense(currentTense)})`);
      } else {
        props.setPlaceHolder(
          `${en} ${verbEntry[en]} (${fmtTense(currentTense)})`
        );
      }
    }
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setColor(textInput, props.answer);
  }

  function setColor(
    textInput: React.RefObject<HTMLInputElement>,
    answer: string
  ) {
    if (textInput.current) {
      const haveValue = textInput.current.value;
      let color = isValid(haveValue, answer) ? "black" : "red";
      color = textInput.current.value === answer ? "green" : color;
      textInput.current.style.color = color;
    }
  }

  function addAccent(accent: string) {
    if (textInput.current?.value != null) {
      textInput.current.value += accent;
      // onChange doesn't fire here, so we do it manually.
      setColor(textInput, props.answer);
    }
  }

  function submitAnswer(answer = '', fromAutoplay=false) {
    fromAutoplay = autoPlay || fromAutoplay;

    if (textInput.current?.value != null) {
      const { currentVerb, currentTense, currentPronoun } = currentRecord();
      const verbEntry: TenseItems = currentVerb[currentTense];
      const { es } = getPronounPair(currentPronoun);
      answer = answer ? answer : textInput.current.value;
      // Update the text input.
      if (textInput.current.value !== answer) {
        textInput.current.value = answer;
      }

      // Answer is correct. Update stats.
      if (answer === props.answer && buttonState.mode === "Submit") {
        // Don't score when autoPlay is active.
        if (!fromAutoplay) {
          props.setStats((stats) =>
            Object.assign(stats, {
              correct: stats.correct + 1,
              total: stats.total + 1,
            })
          );
        }
        // Set the button to green since it was incorrect.
        setButtonState((bs) => Object.assign(bs, { style: "is-success" }));
        // buttonState.mode is now "Next".
        toggleInputMode();
        // Play the Spanish audio.
        if (props.activeAudio) {
          playAudio(verbEntry, es);
        }
        // Refresh the widget.
        props.updateParent({});
        // break
        return;
        // Answer is incorrect. Update stats.
      } else if (answer !== props.answer && buttonState.mode === "Submit") {
        // Don't score when autoPlay is active.
        if (!fromAutoplay) {
          props.setStats((stats) =>
            Object.assign(stats, { total: stats.total + 1 })
          );
        }
        // Give the correct answer.
        textInput.current.value = props.answer;
        // Set the button to red since it was incorrect.
        setButtonState((bs) => Object.assign(bs, { style: "is-danger" }));
        // buttonState.mode is now "Next"
        toggleInputMode();
        // Play the Spanish audio.
        if (props.activeAudio) {
          playAudio(verbEntry, es);
        }
        // Change the color to green.
        setColor(textInput, props.answer);
        // Refresh the widget.
        props.updateParent({});
        // break
        return;
      }

      // Input mode is "Next". Set it back to "Submit".
      toggleInputMode();
      // Set the button to neutral color since it was incorrect.
      setButtonState((bs) => Object.assign(bs, { style: defaultButtonState.style }));
      // Clear the text.
      textInput.current.value = "";
      // Clear the button choices.
      setButtonChoice([]);
      // Move the conjugation forward a click.
      nextConjugation();
      // Update the display for next verb.
      currentConjugation(false);
      // Refresh the widget.
      props.updateParent({});
    }
  }

  function keyPress(e: React.KeyboardEvent<HTMLInputElement>) {
    let newKey: string = "";
    const code = e.key || e.keyCode || e.charCode;

    switch (code) {
      case "A":
        newKey = "á";
        break;
      case "E":
        newKey = "é";
        break;
      case "I":
        newKey = "í";
        break;
      case "O":
        newKey = "ó";
        break;
      case "U":
        newKey = "ú";
        break;
      default:
        newKey = e.key;
    }

    if (code === "Enter" || newKey !== code) {
      e.preventDefault();
      e.stopPropagation();

      if (textInput.current?.value != null) {
        if (code === "Enter") {
          submitAnswer();
        } else {
          textInput.current.value += newKey;
          // onChange doesn't fire here, so we do it manually.
          setColor(textInput, props.answer);
        }
      }
    }
  }

  return (
    <div className="control">
      <input
        name={props.name}
        className="input is-large"
        type="text"
        spellCheck={false}
        placeholder={props.placeholder}
        onKeyDown={keyPress}
        onChange={handleChange}
        ref={textInput}
      />

      <div className="columns mt-2 accents">
        {accents.map((accent, idx) => {
          return (
            <div key={idx} className="column">
              <button className="button" onClick={() => addAccent(accent)}>
                {accent}
              </button>
            </div>
          );
        })}
      </div>

      <div className="control mt-3">
        <button className="button autoplay" onClick={() => toggleAutoPlay()}>
          <FontAwesomeIcon icon={autoPlay ? faStop : faPlay} size="lg" />
        </button>
        <button
          className="button ml-2 repeat"
          onClick={() => playCurrentAudio()}
        >
          Say It
        </button>
        <button
          className="button ml-2 multiple-choice"
          disabled={buttonChoice.length > 0}
          onClick={() => randVerbs()}
        >
          <FontAwesomeIcon icon={faThLarge} size="lg" />
        </button>
        <button
          className={`button ml-2 main-submit ${buttonState.style}`}
          onClick={() => submitAnswer()}
        >
          {buttonState.mode}
        </button>
      </div>

      {buttonChoice.length > 0 && (
        <div className="columns mt-6">
          {buttonChoice.map((value) => {
            return (
              <div className="column">
                <button
                  className="button is-link"
                  onClick={() => submitAnswer(value)}
                >
                  {value}
                </button>
              </div>
            );
          })}
        </div>
      )}
      <audio
        ref={audioRef}
        autoPlay={true}
        onEnded={finishAudio}
      ></audio>
    </div>
  );
};

export default UserInput;

import { TenseData } from "./types";

const INDICATIVE_PRESENT = `
<p>This is the most common tense in Spanish.</p><br>
<p>It indicates that an action or state of being is occuring at the present time.</p>
<br>
<p class="ml-4"><b>Hablo</b> español. I <i>speak</i> Spanish.</p>
<br>
<p>Full explanation: <a target="_blank" href="https://espanol.lingolia.com/en/grammar/tenses/presente">English</a>
   | <a target="_blank" href="https://espanol.lingolia.com/es/gramatica/tiempos/presente">Spanish</>.
</p>
`;

const INDICATIVE_PRETERITE = `
<p>This tense is used to describe an action that was completed in the past.</p>
<br>
<p class="ml-4">Mi padre <b>llegó</b> ayer. My father <i>arrived</i> yesterday.</p>
<br>
<p>Full explanation: <a target="_blank" href="https://espanol.lingolia.com/en/grammar/tenses/preterito-indefinido">English</a>
   | <a target="_blank" href="https://espanol.lingolia.com/es/gramatica/tiempos/preterito-indefinido">Spanish</>.
</p>
`;

const INDICATIVE_IMPERFECT = `
<p>This tense is used to describe an ongoing past action or an action that was interrupted by another action.</p>
<br>
<p class="ml-4">Ella <b>hablaba</b> de cosas interesantes. She <i>talked</i> about interesting things.</p>
<br>
<p class="ml-4">Mi hermano <b>cantaba</b> cuando yo entré. My brother was <i>singing</> when I came in.</p>
<br>
<p>Full explanation: <a target="_blank" href="https://espanol.lingolia.com/en/grammar/tenses/preterito-imperfecto">English</a>
   | <a target="_blank" href="https://espanol.lingolia.com/es/gramatica/tiempos/preterito-imperfecto">Spanish</>.
</p>
`;

const INDICATIVE_CONDITIONAL = `
<p>This tense is used to describe hypothetical situations as well as polite requests.</p>
<br>
<p class="ml-4"><b>Iría</b> a Argentina si tuviera dinero. I <i>would go</i> to Argentina if I had money.</p>
<br>
<p class="ml-4">Me <b>gustaría</b> pedir su ayuda. I <i>would like</i> to ask for your help.</p>
<br>
<p>Full explanation: <a target="_blank" href="https://espanol.lingolia.com/en/grammar/tenses/condicional-simple">English</a>
   | <a target="_blank" href="https://espanol.lingolia.com/es/gramatica/tiempos/condicional-simple">Spanish</>.
</p>
`;

const INDICATIVE_FUTURE = `
<p>This tense is used to describe a forthcoming action or probable event.</p>
<br>
<p class="ml-4"><b>Comeremos</b> pizza si tú la pagas. We <i>will eat</i> pizza if you pay for it.</p>
<br>
<p class="ml-4">María <b>estará</b> enferma. Mary is <i>probably</i> sick.</p>
<br>
<p>Full explanation: <a target="_blank" href="https://espanol.lingolia.com/en/grammar/tenses/futuro-imperfecto">English</a>
   | <a target="_blank" href="https://espanol.lingolia.com/es/gramatica/tiempos/futuro-simple">Spanish</>.
</p>
`;

const SUBJUNCTIVE_PRESENT = `
<p>This tense can be used to speak about both present and future occurring actions.</p><br>
<p>It is used in conjuction with both simple sentences and dependent clauses to express desires,
doubts, the unknown, and emotions.</p><br>
<p>The subjunctive mood is much more common in Spanish than in English.</p>
</p>
<br>
<p class="ml-4">Quiero que Miguel lo <b>haga</b>. I want Miguel to <i>do</i> it.</p>
<br>
<p class="ml-4">Espero que <b>estés</b> bien. I hope that <i>you are</i> well.</p>
<br>
<p class="ml-4">Que <b>tengas</b> un buen día. <i>Have</i> a nice day.</p>
<br>
<p>Full explanation: <a target="_blank" href="https://espanol.lingolia.com/en/grammar/subjuntivo/presente">English</a>
   | <a target="_blank" href="https://espanol.lingolia.com/es/gramatica/tiempos-subjuntivo/presente">Spanish</>.
</p>
`;

const SUBJUNCTIVE_IMPERFECT = `
<p>This tense is used for the same reasons as the present subjunctive, such as the expression<br>
of desires, doubts, the unknown, and emotions, but it is used when discussing<br>
situations which have happened in the past.</p>
<br>
<p class="ml-4">Insistí en que Pedro lo <b>hiciera</b>. I insisted that Peter <i>do</i> it.</p>
<br>
<p class="ml-4">Si <b>tuviera</b> más dinero, me compraría una casa en Madrid. If I had more money,
                I'd buy a house in Madrid.</p>
<br>
<p>Full explanation: <a target="_blank" href="https://espanol.lingolia.com/en/grammar/subjuntivo/preterito-imperfecto">English</a>
   | <a target="_blank" href="https://espanol.lingolia.com/es/gramatica/tiempos-subjuntivo/preterito-imperfecto">Spanish</>.
</p>
`;

const SUBJUNCTIVE_FUTURE = `
<p>This tense is no longer used in modern spoken Spanish, as the<br>
   present subjunctive is used in its place.</p><br>
<p>That said, the future subjunctive still occurs in literary contexts and in<br>
   legal documents, so it may be of interest to some students.</p><br>
<p>For everyday Spanish, this is a non-essential tense.</p><br>
<p class="ml-4">Adonde <b>fueres</b>, haz lo que <b>vieres</b>. When in Rome, do as the Romans do.</p>
<br>
<p>Full explanation: <a target="_blank" href="https://www.spanishdict.com/guide/spanish-future-subjunctive">English</a>.</p>
`;

const IMPERATIVE = `
<p>The imperative mood is used to express commands, orders, and requests in a direct fashion.</p>
<br>
<p class="ml-4">¡<b>Entra</b> pronto! <i>Come in</i> quickly!</p>
<br>
<p class="ml-4">¡<b>No corras</b>! <i>Don't run</i>!</p>
<br>
<p class="ml-4">¡<b>Démelo</b>! <i>Give it to me</i>!</p>
<br>
<p>Full explanation: <a target="_blank" href="https://espanol.lingolia.com/en/grammar/verbs/imperative">English</a>
   | <a target="_blank" href="https://espanol.lingolia.com/es/gramatica/verbos/imperativo">Spanish</>.
</p>
`;

const CONTINUOUS_PRESENT = `
<p>This tense is used to talk about actions that are happening now.<p><br>
<p>This tense is formed by using the verb estar (to be) in the present tense with a present participle.</p><br>
<p class="ml-4"><b>Estoy comiendo</b>. <i>I am eating</i>.</p>
<br>
<p class="ml-4"><b>Estamos hablando</b>. <i>We are talking</i>.</p>
<br>
<p>Full explanation: <a target="_blank" href="https://www.spanishdict.com/guide/spanish-present-progressive-forms">English</a>.</p>
`;

const CONTINUOUS_PRETERITE = `
<p>This tense is used to talk about actions that were completed in the past.</p><br>
<p>This tense is formed by using the verb estar (to be) in the preterite tense with a present participle.</p><br>
<p class="ml-4"><b>Estuve comiendo</b> anoche. <i>I was eating</i> last night.</p><br>
<p>Full explanation: <a target="_blank" href="https://www.spanishdict.com/guide/spanish-preterite-progressive-tense">English</a>.</p>
`;

const CONTINUOUS_IMPERFECT = `
<p>This tense is used to talk about actions that were ongoing in the past.</p><br>
<p>This tense is formed by using the verb estar (to be) in the imperfect indicative tense with a present participle.</p><br>
<p class="ml-4"><b>Estaba comiendo</b>. <i>I was eating</i>.</p><br>
<p>Full explanation: <a target="_blank" href="https://www.spanishdict.com/guide/spanish-imperfect-progressive-tense">English</a>.</p>
`;

const CONTINUOUS_CONDITIONAL = `
<p>This tense is used to talk about things that would happen if another action occurred.</p><br>
<p>This tense is formed by using the verb estar (to be) in the conditional tense with a present participle.</p><br>
<p class="ml-4"><b>Estaría comiendo</b> torta si no estuviera a dieta. <i>I would be eating</i> cake if I weren't on a diet.</p><br>
<p>Full explanation: <a target="_blank" href="https://www.spanishdict.com/guide/spanish-conditional-progressive-tense">English</a>.</p>
`;

const CONTINUOUS_FUTURE = `
<p>This tense is used to talk about things that will be happening in the future.</p><br>
<p>This tense is formed by using the verb estar (to be) in the future tense with a present participle.</p><br>
<p class="ml-4"><b>Estaré comiendo</b> torta mañana. <i>I will be eating</i> cake tomorrow.</p><br>
<p>Full explanation: <a target="_blank" href="https://www.spanishdict.com/guide/spanish-future-progressive-tense">English</a>.</p>
`;

const INDICATIVE_PERFECT_PRESENT = `
<p>This tense is used to talk about actions in the recent past that have a connection to the present.</p><br>
<p>This tense is formed by using the present indicative form of the auxillary verb haber (to have) with a past participle.</p><br>
<p class="ml-4">Yo <b>he hablado</b>. <i>I have spoken</i>.</p><br>
<p class="ml-4"><b>Hemos estado</b> juntos por un tiempo. <i>We have been</i> together for a while.</p><br>
<p>Full explanation: <a target="_blank" href="https://espanol.lingolia.com/en/grammar/tenses/preterito-perfecto">English</a>
   | <a target="_blank" href="https://espanol.lingolia.com/es/gramatica/tiempos/preterito-perfecto">Spanish</>.
</p>
`;

const INDICATIVE_PERFECT_PRETERITE = `
<p>This tense is used to talk about an action that happened directly before another past action.</p>
<p>This tense is uncommon in modern spoken Spanish but can still be found in literary contexts.</p>
<p>In modern Spanish, this tense is typically replaced with the pluperfect (perfect past) tense.</p><br>
<p>This tense is formed by using the preterite indicative form of the auxillary verb haber (to have) with a past participle.</p><br>
<p class="ml-4">En cuanto <b>hubiste llegado</b> a la oficina, se fueron.
                As soon as <i>you arrived</i> at the office, they left.</p><br>
<p>Full explanation: <a target="_blank" href="https://espanol.lingolia.com/en/grammar/tenses/preterito-anterior">English</a>
   | <a target="_blank" href="https://espanol.lingolia.com/es/gramatica/tiempos/preterito-anterior">Spanish</>.
</p>
`;

const INDICATIVE_PERFECT_PAST = `
<p>This tense is used to talk about things that happened before a certain point in the past.</p><br>
<p>This tense is formed by using the imperfect form of the auxillary verb haber (to have) with a past participle.</p><br>
<p class="ml-4">Él <b>había cantado</b> cuando llegamos. He <i>had already sung</i> when we arrived.</p><br>
<p>Full explanation: <a target="_blank" href="https://espanol.lingolia.com/en/grammar/tenses/preterito-pluscuamperfecto">English</a>
   | <a target="_blank" href="https://espanol.lingolia.com/es/gramatica/tiempos/preterito-pluscuamperfecto">Spanish</>.
</p>
`;

const INDICATIVE_PERFECT_CONDITIONAL = `
<p>This tense is used to talk about things that could have happened in the past.</p><br>
<p>This tense is formed by using the conditional simple of the auxillary verb haber (to have) with a past participle.</p><br>
<p class="ml-4">Yo <b>habría comprado</b> un boleto, pero no tenía tiempo. I would have bought a ticket, but I didn't have time.</p><br>
<p>Full explanation: <a target="_blank" href="https://espanol.lingolia.com/en/grammar/tenses/condicional-compuesto">English</a>
   | <a target="_blank" href="https://espanol.lingolia.com/es/gramatica/tiempos/condicional-compuesto">Spanish</>.
</p>
`;

const INDICATIVE_PERFECT_FUTURE = `
<p>This tense is used to talk about a future action that will have concluded before another future action.</p>
<p>It may also express an assumption that another action would have happened in the past.</p><br>
<p>This tense is formed by using the future form of the auxillary verb haber (to have) with a past participle.</p><br>
<p class="ml-4">Ya <b>habré terminado</b> mi trabajo cuando llegas. I <i>will have</i> already <i>finished</i> my work when you arrive.</p>
<br>
<p>Full explanation: <a target="_blank" href="https://espanol.lingolia.com/en/grammar/tenses/futuro-perfecto">English</a>
   | <a target="_blank" href="https://espanol.lingolia.com/es/gramatica/tiempos/futuro-perfecto">Spanish</>.
</p>
`;

const SUBJUNCTIVE_PERFECT_PRESENT = `
<p>This tense is used to express desires, doubts, the unknown, and emotions about actions that are<br>
connected to the present, or will have happened by a certain time in the future.</p><br>
<p>This tense is formed by using the present subjunctive form of the auxillary verb haber (to have) with a past participle.</p><br>
<p class="ml-4">Mi madre duda que <b>haya limpiado</b> mi cuarto. My mother doubts that <i>I have cleaned</i> my room.</p><br>
<p>Full explanation: <a target="_blank" href="https://www.spanishdict.com/guide/spanish-present-perfect-subjunctive">English</a>.</p>
`;

const SUBJUNCTIVE_PERFECT_PAST = `
<p>This tense is used to talk about hypothetical actions that took place in the past.</p>
<p>This tense is formed by using the imperfect subjunctive of the auxillary verb haber (to have) with a past participle.</p><br>
<p class="ml-4">Ojalá que <b>hubiera sabido</b>. I wish I <i>had known</i>.</p><br>
<p class="ml-4">Si <b>hubiera tenido</b> tiempo, te ayudaría. If I'd <i>had</i> time, I would have helped you.</p><br>
<p>Full explanation: <a target="_blank" href="https://www.spanishdict.com/guide/past-perfect-subjunctive-forms">English</a>.</p>
`;

const SUBJUNCTIVE_PERFECT_FUTURE = `
<p>Like the subjunctive future tense, this tense is extremely rare in modern Spanish.</p><br>
<p>That said, this tense still occurs in literary contexts and in legal documents, so it may be <br>
   of interest to some students.</p><br>
<p>This tense is formed by using the future subjunctive of the auxillary verb haber (to have) with a past participle.</p><br>
<p>For everyday Spanish, this is a non-essential tense.</p><br>
<p class="ml-4">Cuando tengo 50 años, <b>hubiere viajado</b> por todo el mundo. When I am 50, I <i>will have traveled</i> the entire world.</p>
<br>
<p>Full explanation: <a target="_blank" href="https://www.spanishdict.com/guide/spanish-future-perfect-subjunctive">English</a>.</p>
`;

export type TenseTooltip = Record<string, string>;
export const tenseTooltips: TenseTooltip = {
    "indicative_present": INDICATIVE_PRESENT,
    "indicative_preterite": INDICATIVE_PRETERITE,
    "indicative_imperfect": INDICATIVE_IMPERFECT,
    "indicative_conditional": INDICATIVE_CONDITIONAL,
    "indicative_future": INDICATIVE_FUTURE,
    "subjunctive_present": SUBJUNCTIVE_PRESENT,
    "subjunctive_imperfect": SUBJUNCTIVE_IMPERFECT,
    "subjunctive_future": SUBJUNCTIVE_FUTURE,
    "imperative_negative": IMPERATIVE,
    "imperative_affirmative": IMPERATIVE,
    "continuous_present": CONTINUOUS_PRESENT,
    "continuous_preterite": CONTINUOUS_PRETERITE,
    "continuous_imperfect": CONTINUOUS_IMPERFECT,
    "continuous_conditional": CONTINUOUS_CONDITIONAL,
    "continuous_future": CONTINUOUS_FUTURE,
    "indicative_perfect_present": INDICATIVE_PERFECT_PRESENT,
    "indicative_perfect_preterite": INDICATIVE_PERFECT_PRETERITE,
    "indicative_perfect_past": INDICATIVE_PERFECT_PAST,
    "indicative_perfect_conditional": INDICATIVE_PERFECT_CONDITIONAL,
    "indicative_perfect_future": INDICATIVE_PERFECT_FUTURE,
    "subjunctive_perfect_present": SUBJUNCTIVE_PERFECT_PRESENT,
    "subjunctive_perfect_past": SUBJUNCTIVE_PERFECT_PAST,
    "subjunctive_perfect_future": SUBJUNCTIVE_PERFECT_FUTURE,
}

const tenseData: TenseData[] = [
    ["Indicative Present (I speak)", "indicative_present"],
    ["Indicative Preterite (I spoke)", "indicative_preterite"],
    ["Indicative Imperfect (I spoke)", "indicative_imperfect"],
    ["Indicative Conditional (I would speak)", "indicative_conditional"],
    ["Indicative Future (I will speak)", "indicative_future"],
    ["Subjunctive Present (I speak)", "subjunctive_present"],
    ["Subjunctive Imperfect (I spoke)", "subjunctive_imperfect"],
    ["Subjunctive Future (I will speak)", "subjunctive_future"],
    ["Imperative Negative (Don't speak)", "imperative_negative"],
    ["Imperative Affirmative (Speak!)", "imperative_affirmative"],
    ["Continuous Present (I am speaking)", "continuous_present"],
    ["Continuous Preterite (I was speaking)", "continuous_preterite"],
    ["Continuous Imperfect (I was speaking)", "continuous_imperfect"],
    ["Continuous Conditional (I would be speaking)", "continuous_conditional"],
    ["Continuous Future (I will be speaking)", "continuous_future"],
    ["Indicative Perfect Present (I have spoken)", "indicative_perfect_present"],
    ["Indicative Perfect Preterite (I had spoken)", "indicative_perfect_preterite"],
    ["Indicative Perfect Past (I had spoken)", "indicative_perfect_past"],
    ["Indicative Perfect Conditional (I would have spoken)", "indicative_perfect_conditional"],
    ["Indicative Perfect Future (I will have spoken)", "indicative_perfect_future"],
    ["Subjunctive Perfect Present (I have spoken)", "subjunctive_perfect_present"],
    ["Subjunctive Perfect Past (I had spoken)", "subjunctive_perfect_past"],
    ["Subjunctive Perfect Future (I will have spoken)", "subjunctive_perfect_future"],
];

export { tenseData };

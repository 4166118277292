import { Option } from "react-multi-select-component/dist/lib/interfaces";

export interface TextEntryProps {
    answer: string;
    setAnswer: React.Dispatch<React.SetStateAction<string>>;
}

export interface Stats {
    correct: number;
    total: number;
    reviewing: number;
}

export interface CurrentPos {
    verb: string;
    pronoun: PronounKey;
    tense: TenseKey;
    phrases: ExamplePhrase[];
}

export const SP_PRONOUN_KEYS = [
    "yo",
    "tú",
    "él/ella/usted",
    "nosotros/nosotras",
    "vosotros/vosotras",
    "ellos/ellas/ustedes",
];

export const SP_PRONOUN_AUDIO_KEYS = [
    "audio_yo",
    "audio_tú",
    "audio_él/ella/usted",
    "audio_nosotros/nosotras",
    "audio_vosotros/vosotras",
    "audio_ellos/ellas/ustedes",
];

export const EN_PRONOUN_KEYS = [
    "I",
    "You",
    "She",
    "We",
    "Y'all",
    "They",
];

export const EN_PRONOUN_AUDIO_KEYS = [
    "audio_I",
    "audio_You",
    "audio_She",
    "audio_We",
    "audio_Y'all",
    "audio_They",
];

export const PRONOUN_KEYS = [
    ...SP_PRONOUN_KEYS,
    ...SP_PRONOUN_AUDIO_KEYS,
    ...EN_PRONOUN_KEYS,
    ...EN_PRONOUN_AUDIO_KEYS
] as const;

export type PronounKey = typeof PRONOUN_KEYS[number];

export const pronounMap: Record<PronounKey, string> = {
    "yo": "yo",
    "tú": "tú",
    "él/ella/usted": "ella",
    "nosotros/nosotras": "nosotros",
    "vosotros/vosotras": "vosotros",
    "ellos/ellas/ustedes": "ellos"
};

export type PronounData = [string, PronounKey];

export const TENSE_KEYS = [
    "indicative_present",
    "indicative_preterite",
    "indicative_imperfect",
    "indicative_conditional",
    "indicative_future",
    "subjunctive_present",
    "subjunctive_imperfect",
    "subjunctive_future",
    "imperative_negative",
    "imperative_affirmative",
    "continuous_present",
    "continuous_preterite",
    "continuous_imperfect",
    "continuous_conditional",
    "continuous_future",
    "indicative_perfect_present",
    "indicative_perfect_preterite",
    "indicative_perfect_past",
    "indicative_perfect_conditional",
    "indicative_perfect_future",
    "subjunctive_perfect_present",
    "subjunctive_perfect_past",
    "subjunctive_perfect_future",
] as const;

export type TenseKey = typeof TENSE_KEYS[number];

type TenseData = [string, TenseKey];

export type TenseItems = Record<PronounKey, string>;

export type ExamplePhrase = {
    en: string,
    es: string,
}

interface VerbRecord {
    inf: string;
    ending: string;
    trans: string;
    past: string;
    past_en: string;
    gerund: string;
    gerund_en: string;
    is_regular: bool;
    indicative_present: TenseItems;
    indicative_preterite: TenseItems;
    indicative_imperfect: TenseItems;
    indicative_conditional: TenseItems;
    indicative_future: TenseItems;
    subjunctive_present: TenseItems;
    subjunctive_imperfect: TenseItems;
    indicative_perfect_present: TenseItems;
    indicative_perfect_preterite: TenseItems;
    indicative_perfect_past: TenseItems;
    indicative_perfect_conditional: TenseItems;
    indicative_perfect_future: TenseItems;
    imperative_negative: TenseItems;
    imperative_affirmative: TenseItems;
    subjunctive_perfect_present: TenseItems;
    subjunctive_perfect_past: TenseItems;
    subjunctive_perfect_future: TenseItems;
    continuous_present: TenseItems;
    continuous_preterite: TenseItems;
    continuous_imperfect: TenseItems;
    continuous_conditional: TenseItems;
    continuous_future: TenseItems;
    subjunctive_future: TenseItems;
    examples: ExamplePhrase[];
}

export interface ExampleProps {
    phrases: ExamplePhrase[];
}

type VerbRecords = Record<string, VerbRecord>;

export type ActiveList = Set<string>;

export type Tab = "verbs" | "tenses" | "pronouns";

export type TabData = Record<Tab, SettingsPanelItemProps[]>;

export type ItemData = [string, string] | PronounData | TenseData;

export type SetActiveList = React.Dispatch<React.SetStateAction<ActiveList>>;

export interface AudioToggleProps {
    activeAudio: boolean;
    setActiveAudio: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface SettingsPanelProps {
    heading: string;
    activeTab: Tab;
    setActiveTab: React.Dispatch<React.SetStateAction<Tab>>;
    tabData: TabData;
    setTabData: React.Dispatch<React.SetStateAction<TabData>>;
    tabDataFiltered: TabData;
    setTabDataFiltered: React.Dispatch<React.SetStateAction<TabData>>;
    activeVerbs: ActiveList;
    setActiveVerbs: SetActiveList;
    activeTenses: ActiveList;
    setActiveTenses: SetActiveList;
    activePronouns: ActiveList;
    setActivePronouns: SetActiveList;
}

export interface BaseSettingsPanelItemProps {
    id: number;
    tab: string;
    icon: string;
    label: string;
    value: string;
}

interface ActiveParams {
    activeTab: Tab;
    activeVerbs: ActiveList;
    setActiveVerbs: SetActiveList;
    activeTenses: ActiveList;
    setActiveTenses: SetActiveList;
    activePronouns: ActiveList;
    setActivePronouns: SetActiveList;
    updateParent?: React.Dispatch<React.SetStateAction<any>>;
}

interface TourButtonProps {
    runTour: boolean;
    setRunTour: React.Dispatch<React.SetStateAction<boolean>>;
    updateParent: React.Dispatch<React.SetStateAction<any>>;
}

type SetCurrentPos = React.Dispatch<React.SetStateAction<CurrentPos>>;
export type SetAnswer = React.Dispatch<React.SetStateAction<string>>;
export type SetPlaceHolder = React.Dispatch<React.SetStateAction<string>>;
export type SetStats = React.Dispatch<React.SetStateAction<Stats>>;

export interface BaseUserInputProps {
    name: string;
    verbs: VerbRecords;
    answer: string;
    placeholder: string;
    activeAudio: boolean;
    currentPos: CurrentPos;
    setCurrentPos: SetCurrentPos;
    setAnswer: SetAnswer;
    setPlaceHolder: SetPlaceHolder;
    setStats: SetStats;
    updateParent: React.Dispatch<React.SetStateAction<any>>;
}

export type UserInputProps = BaseUserInputProps & ActiveParams;
export type SettingsPanelItemProps = BaseSettingsPanelItemProps & ActiveParams;
export type SettingsPanelResetProps = ActiveParams;

export interface SettingsPanelSearchProps {
    activeTab: Tab;
    tabData: TabData;
    tabDataFiltered: TabData;
    setTabDataFiltered: React.Dispatch<React.SetStateAction<TabData>>;
    updateParent: React.Dispatch<React.SetStateAction<any>>;
}

export interface SettingsPanelTabsProps {
    activeTab: Tab;
    setActiveTab: React.Dispatch<React.SetStateAction<Tab>>;
    updateParent: React.Dispatch<React.SetStateAction<any>>;
}

export interface TextEntryProps {
    answer: string;
    setAnswer: React.Dispatch<React.SetStateAction<string>>;
}

export interface CounterProps {
    name: string;
    count: number;
    setCount: React.Dispatch<React.SetStateAction<number>>;
}

export interface FilterProps {
    selected: Option[];
    setSelected: React.Dispatch<React.SetStateAction<Option[]>>;
}

export interface JsonData {
    type: string;
    region: string;
}

export interface DisplayProps {
    data: JsonData[];
    selected: Option[];
}
import React, { useState } from "react";
import ReactToolTip from "react-tooltip";
import Joyride from "react-joyride";
import logo from "./logo.png";
import "./App.scss";
import TourButton, { tourSteps, handleJoyrideCallback } from "./TourButton";
import Timer from "./Timer";
import Examples from "./Examples";
import { initStats } from "./Init";
import {
  TabData,
  Tab,
  ActiveList,
  Stats,
  VerbRecords,
  CurrentPos,
  pronounMap,
} from "./types.d";
import { pctCorrect } from "./Utils";
import { pronounData } from "./SettingsPanelPronounData";
import { tenseData } from "./SettingsPanelTenseData";
import { verbData } from "./SettingsPanelVerbData";
import SettingsPanel from "./SettingsPanel";

import {
  initActiveVerbs,
  initActiveTenses,
  initActivePronouns,
  initVerb,
  initTense,
  initPronoun,
  makePanelItemProps,
} from "./SettingsPanelUtil";
import UserInput from "./UserInput";
import AudioToggle from "./AudioToggle";

function App() {
  const verbs: VerbRecords = require("./data/verbs_final.json");
  const [, setState] = useState();
  const [stats, setStats] = useState<Stats>(initStats);
  const [activeAudio, setActiveAudio] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<Tab>("verbs");
  const [activeVerbs, setActiveVerbs] = useState<ActiveList>(initActiveVerbs());
  const [activeTenses, setActiveTenses] = useState<ActiveList>(initActiveTenses());
  const [activePronouns, setActivePronouns] = useState<ActiveList>(initActivePronouns());
  const [runTour, setRunTour] = useState(false);
  const [answer, setAnswer] = useState<string>("");
  const [placeholder, setPlaceHolder] = useState<string>("");
  const [currentPos, setCurrentPos] = useState<CurrentPos>({
    verb: initVerb,
    pronoun: initPronoun,
    tense: initTense,
    phrases: verbs[initVerb].examples,
  });

  const initTabData: TabData = {
    pronouns: makePanelItemProps(
      "pronouns",
      activeTab,
      activeVerbs,
      setActiveVerbs,
      activeTenses,
      setActiveTenses,
      activePronouns,
      setActivePronouns,
      pronounData
    ),
    tenses: makePanelItemProps(
      "pronouns",
      activeTab,
      activeVerbs,
      setActiveVerbs,
      activeTenses,
      setActiveTenses,
      activePronouns,
      setActivePronouns,
      tenseData
    ),
    verbs: makePanelItemProps(
      "pronouns",
      activeTab,
      activeVerbs,
      setActiveVerbs,
      activeTenses,
      setActiveTenses,
      activePronouns,
      setActivePronouns,
      verbData
    ),
  };

  const [tabData, setTabData] = useState<TabData>(initTabData);
  const [tabDataFiltered, setTabDataFiltered] = useState<TabData>(initTabData);

  return (
    <div className="site">
      <ReactToolTip html={true} delayHide={1000} />
      <Joyride steps={tourSteps}
               run={runTour} 
               showProgress={true}
               showSkipButton={true}
               continuous={true}
               callback={handleJoyrideCallback(setRunTour)}
               styles={{
                 options: {
                   primaryColor: "#2985d6"
                 }
                }}
               />
      <div className="approot site-content">
        <nav className="navbar is-fixed-top">
          <div className="navbar-item">
            <img src={logo} alt="Verbose.ly: Spanish verb mastery with ease!" />
          </div>
          <div className="navbar-end">
            <AudioToggle
              activeAudio={activeAudio}
              setActiveAudio={setActiveAudio}
            />

            <div className="navbar-item">
              <TourButton updateParent={setState} runTour={runTour} setRunTour={setRunTour} />
            </div>
          </div>
        </nav>

        <nav className="level top-pad">
          <div className="level-item has-text-centered">
            <div>
              <p className="heading">Correct</p>
              <p className="title">{stats.correct}</p>
            </div>
          </div>
          <div className="level-item has-text-centered">
            <div>
              <p className="heading">Incorrect</p>
              <p className="title">{stats.total - stats.correct}</p>
            </div>
          </div>
          <div className="level-item has-text-centered">
            <div>
              <p className="heading">% Correct</p>
              <p className="title">{pctCorrect(stats.correct, stats.total)}</p>
            </div>
          </div>
          <div className="level-item has-text-centered">
            <div>
              <p className="heading">Seen</p>
              <p className="title">{stats.total}</p>
            </div>
          </div>
          <div className="level-item has-text-centered">
            <div>
              <p className="heading">Reviewing</p>
              <p className="title">{stats.reviewing}</p>
            </div>
          </div>
          <div className="level-item has-text-centered">
            <div>
              <p className="heading">Time</p>
              <p className="title">
                <Timer />
              </p>
            </div>
          </div>
        </nav>

        <div className="columns mt examples">
          <Examples phrases={currentPos.phrases} />

          <div className="column">
            <div className="field is-grouped is-grouped-multiline verb-pronoun">
              <div className="control">
                <div className="tags has-addons">
                  <span className="tag">Verb</span>
                  <span className="tag is-warning">{currentPos.verb}</span>
                </div>
              </div>

              <div className="control">
                <div className="tags has-addons">
                  <span className="tag">Pronoun</span>
                  <span className="tag is-primary">
                    {pronounMap[currentPos.pronoun]}
                  </span>
                </div>
              </div>
            </div>

            <UserInput
              name="userInput"
              verbs={verbs}
              answer={answer}
              placeholder={placeholder}
              activeAudio={activeAudio}
              currentPos={currentPos}
              setCurrentPos={setCurrentPos}
              setAnswer={setAnswer}
              setPlaceHolder={setPlaceHolder}
              setStats={setStats}
              updateParent={setState}
              activeTab={activeTab}
              activeVerbs={activeVerbs}
              setActiveVerbs={setActiveVerbs}
              activeTenses={activeTenses}
              setActiveTenses={setActiveTenses}
              activePronouns={activePronouns}
              setActivePronouns={setActivePronouns}
            />
          </div>
          <div className="settings-panel column mr">
            <SettingsPanel
              heading="Settings"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              tabData={tabData}
              setTabData={setTabData}
              tabDataFiltered={tabDataFiltered}
              setTabDataFiltered={setTabDataFiltered}
              activeVerbs={activeVerbs}
              setActiveVerbs={setActiveVerbs}
              activeTenses={activeTenses}
              setActiveTenses={setActiveTenses}
              activePronouns={activePronouns}
              setActivePronouns={setActivePronouns}
            />
          </div>
        </div>

        <nav className="level">
          <div className="level-left">
            <div className="level-item"></div>
          </div>

          <div className="level-right"></div>
        </nav>
      </div>
      <div className="footex">
        <div className="content has-text-centered">
          <p>
            <strong>
              verbose.ly created by @tmountain | About | Terms of Use | Privacy
              Policy
            </strong>
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;

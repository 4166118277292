import { PronounData } from "./types.d";

const pronounData: PronounData[] = [
    ["yo (I)", "yo"],
    ["tú (You)", "tú"],
    ["él/ella/Ud. (He/She/You)", "él/ella/usted"],
    ["nosotros (Us)", "nosotros/nosotras"],
    ["vosotros (Y'all)", "vosotros/vosotras"],
    ["ellos/ellas/Uds. (They/They/You All)", "ellos/ellas/ustedes"],
]

export { pronounData };

import { Tab, ActiveList, SetActiveList, ItemData, SettingsPanelItemProps, ActiveParams, PronounKey, TenseKey } from "./types.d";

export const initVerb: string = "hablar";

export function initActiveVerbs() : Set<string> {
    return new Set<string>().add(initVerb);
}

export const initTense: TenseKey = "indicative_present";

export function initActiveTenses() : Set<string> {
    return new Set<string>().add(initTense);
}

export const initPronoun: PronounKey = "yo";

export function initActivePronouns() : Set<string> {
    return new Set<string>().add("yo")
                            .add("tú")
                            .add("él/ella/usted")
                            .add("nosotros/nosotras")
                            .add("vosotros/vosotras")
                            .add("ellos/ellas/ustedes")
}

export function getActiveForTab(props: ActiveParams): [ActiveList, SetActiveList] {
    switch (props.activeTab) {
        case "verbs":
            return [props.activeVerbs, props.setActiveVerbs];
        case "tenses":
            return [props.activeTenses, props.setActiveTenses];
        case "pronouns":
            return [props.activePronouns, props.setActivePronouns];
    }
}

export function makePanelItemProps(tab: Tab, activeTab: Tab, activeVerbs: ActiveList, setActiveVerbs: SetActiveList, activeTenses: ActiveList, setActiveTenses: SetActiveList, activePronouns: ActiveList, setActivePronouns: SetActiveList, data: ItemData[]): SettingsPanelItemProps[] {
    let results: SettingsPanelItemProps[] = [];
    data.forEach((item, index) => {
        let [label, value] = item;
        let row: SettingsPanelItemProps = {
            id: index + 1,
            tab: tab,
            icon: "fa-book",
            label: label,
            value: value,
            activeTab,
            activeVerbs,
            setActiveVerbs,
            activeTenses,
            setActiveTenses,
            activePronouns,
            setActivePronouns
        }
        results.push(row);
    })
    return results;
}
import React, { useState, useEffect } from 'react';

const Timer = () => {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 1000);
    return () => clearInterval(interval);
  });

  function toString(): string {
      const secsPerHour = 60 * 60;
      const totalMinutes = Math.floor(seconds / 60);
      const hours = Math.floor(totalMinutes / 60)
      const minutes = totalMinutes - (hours * 60);
      const secs = seconds - (hours * secsPerHour) - (minutes * 60);
      return [hours.toString().padStart(2, '0'),
              minutes.toString().padStart(2, '0'),
              secs.toString().padStart(2, '0')].join(':');
  }

  return (
      <span>{toString()}</span>
  );
};

export default Timer;
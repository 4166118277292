import { ItemData } from "./types";

const verbData: ItemData[] = [
    [ "ubicar (to place)",  "ubicar" ],
    [ "fabricar (to manufacture)",  "fabricar" ],
    [  "dejar (to leave, abandon, to let)", "dejar" ],
    [ "tapar (to cover)", "tapar" ],
    [ "aceptar (to accept)", "aceptar" ],
    [ "beber (to drink)", "beber" ],
    [ "renunciar (to renounce, surrender, resign)", "renunciar" ],
    [ "consentir (to consent to)", "consentir" ],
    [ "declarar (to declare)", "declarar" ],
    [ "gastar (to spend, expend, to use up)", "gastar" ],
    [ "investigar (to investigate)", "investigar" ],
    [ "yacer (to lie)", "yacer" ],
    [ "componer (to compose)", "componer" ],
    [ "moverse (to move [oneself])", "moverse" ],
    [ "oponer (to pit [A against B])", "oponer" ],
    [ "oponerse (to put on [clothing])", "oponerse" ],
    [ "comenzar (to begin, start, commence)", "comenzar" ],
    [ "graduar (to graduate)", "graduar" ],
    [ "graduarse (to graduate)", "graduarse" ],
    [ "ir (to go)", "ir" ],
    [ "irse (to go away, leave, depart)", "irse" ],
    [ "ser (to be)", "ser" ],
    [ "florecer (to flourish, flower, bloom)", "florecer" ],
    [ "batir (to beat, hammer, pound [on])", "batir" ],
    [ "odiar (to hate)", "odiar" ],
    [ "andar (to walk, go)", "andar" ],
    [ "mandar (to order)", "mandar" ],
    [ "divertir (to amuse, entertain)", "divertir" ],
    [ "divertirse (to have fun)", "divertirse" ],
    [ "enterarse (to find out [about something])", "enterarse" ],
    [ "presentir (to have a premonition of)", "presentir" ],
    [ "negar (to deny, refuse)", "negar" ],
    [ "negarse (to refuse)", "negarse" ],
    [ "deprimir (to depress)", "deprimir" ],
    [ "introducir (to introduce, insert)", "introducir" ],
    [ "proteger (to protect)", "proteger" ],
    [ "clasificar (to classify, grade, rate)", "clasificar" ],
    [ "pintar (to paint)", "pintar" ],
    [ "regir (to rule)", "regir" ],
    [ "regresar (to return, go back)", "regresar" ],
    [ "esquiar (to ski)", "esquiar" ],
    [ "despedir (to say goodbye to)", "despedir" ],
    [ "acontecer (to happen, occur, come about)", "acontecer" ],
    [ "protestar (to protest)", "protestar" ],
    [ "despertar (to awaken)", "despertar" ],
    [ "despertarse (to wake up, lie down)", "despertarse" ],
    [ "educar (to educate, to bring up)", "educar" ],
    [ "destruir (to destroy)", "destruir" ],
    [ "usar (to use)", "usar" ],
    [ "confiar (to trust, be trusting, entrust)", "confiar" ],
    [ "colonizar (to colonize, settle)", "colonizar" ],
    [ "enamorar (to inspire love in)", "enamorar" ],
    [ "enamorarse (to fall in love)", "enamorarse" ],
    [ "sufrir (to suffer)", "sufrir" ],
    [ "aconsejar (to advise)", "aconsejar" ],
    [ "ilustrar (to illustrate)", "ilustrar" ],
    [ "vestir (to dress)", "vestir" ],
    [ "calcular (to calculate, compute, add up)", "calcular" ],
    [ "constituir (to constitute)", "constituir" ],
    [ "regatear (to haggle (over))", "regatear" ],
    [ "aprender (to learn)", "aprender" ],
    [ "pensar (to think)", "pensar" ],
    [ "votar (to vote)", "votar" ],
    [ "cansar (to tire, tire out, fatigue)", "cansar" ],
    [ "cansarse (to get tired)", "cansarse" ],
    [ "parecer (to seem, appear)", "parecer" ],
    [ "crecer (to grow [up])", "crecer" ],
    [ "romper (to break)", "romper" ],
    [ "bailar (to dance)", "bailar" ],
    [ "torcer (to twist, bend, warp)", "torcer" ],
    [ "sonreír (to smile)", "sonreír" ],
    [ "cerrar (to close, shut)", "cerrar" ],
    [ "cepillar (to brush)", "cepillar" ],
    [ "reportar (to bring, carry, to obtain)", "reportar" ],
    [ "barrer (to sweep)", "barrer" ],
    [ "empezar (to begin, start)", "empezar" ],
    [ "costar (to cost)", "costar" ],
    [ "quedarse (to stay [behind])", "quedarse" ],
    [ "repasar (to review)", "repasar" ],
    [ "importar (to import)", "importar" ],
    [ "establecer (to establish)", "establecer" ],
    [ "dormir (to sleep)", "dormir" ],
    [ "dormirse (to go to sleep, fall asleep)", "dormirse" ],
    [ "derretir (to melt, liquefy, thaw)", "derretir" ],
    [ "callar (to keep quiet about)", "callar" ],
    [ "callarse (to keep quiet)", "callarse" ],
    [ "tropezar (to trip, stumble, slip up)", "tropezar" ],
    [ "brindar (to toast)", "brindar" ],
    [ "invitar (to invite)", "invitar" ],
    [ "merendar (to have [an afternoon] snack)", "merendar" ],
    [ "engañar (to deceive, cheat, trick)", "engañar" ],
    [ "burlar (to deceive, trick, to seduce)", "burlar" ],
    [ "burlarse (to mock)", "burlarse" ],
    [ "enviar (to send)", "enviar" ],
    [ "unir (to unite)", "unir" ],
    [ "gobernar (to govern)", "gobernar" ],
    [ "broncearse (to get a suntan, tan)", "broncearse" ],
    [ "exigir (to demand, require)", "exigir" ],
    [ "triunfar (to triumph, win)", "triunfar" ],
    [ "inmigrar (to immigrate)", "inmigrar" ],
    [ "quebrar (to break, smash, to fail)", "quebrar" ],
    [ "quebrarse (to break, smash, get broken)", "quebrarse" ],
    [ "llorar (to cry)", "llorar" ],
    [ "extinguir (to extinguish, put out)", "extinguir" ],
    [ "querer (to want, love)", "querer" ],
    [ "enfadar (to anger, irritate, annoy)", "enfadar" ],
    [ "enfadarse (to get angry)", "enfadarse" ],
    [ "desagradecer (to be unthankful for)", "desagradecer" ],
    [ "desayunar (to eat breakfast)", "desayunar" ],
    [ "resultar (to turn out (to be))", "resultar" ],
    [ "prometer (to promise)", "prometer" ],
    [ "agorar (to predict, prophesy)", "agorar" ],
    [ "desagradar (to displease, be displeasing)", "desagradar" ],
    [ "llegar (to arrive, come, reach)", "llegar" ],
    [ "satisfacer (to satisfy)", "satisfacer" ],
    [ "disgustar (to displease)", "disgustar" ],
    [ "secarse (to dry, dry off, dry up)", "secarse" ],
    [ "rechazar (to reject, to push back, repel)", "rechazar" ],
    [ "abrazar (to hug, embrace)", "abrazar" ],
    [ "descender (to descend, lower)", "descender" ],
    [ "abusar (to go too far, take advantage)", "abusar" ],
    [ "convidar (to invite)", "convidar" ],
    [ "meter (to put [in], place, insert)", "meter" ],
    [ "satirizar (to satirize)", "satirizar" ],
    [ "deshacer (to undo, unmake, ruin)", "deshacer" ],
    [ "planchar (to iron)", "planchar" ],
    [ "abandonar (to abandon)", "abandonar" ],
    [ "secar (to dry, dry up, dry off)", "secar" ],
    [ "colocar (to locate, place)", "colocar" ],
    [ "suspirar (to sigh)", "suspirar" ],
    [ "resolver (to solve, resolve)", "resolver" ],
    [ "errar (to err, make an error)", "errar" ],
    [ "acordar (to decide, resolve, agree [on])", "acordar" ],
    [ "acordarse (to remember)", "acordarse" ],
    [ "reservar (to reserve)", "reservar" ],
    [ "advertir (to notice, observe, advise)", "advertir" ],
    [ "hacer (to do, make)", "hacer" ],
    [ "servir (to serve)", "servir" ],
    [ "correr (to run)", "correr" ],
    [ "permanecer (to stay, remain)", "permanecer" ],
    [ "exportar (to export)", "exportar" ],
    [ "mostrar (to show)", "mostrar" ],
    [ "conjugar (to conjugate)", "conjugar" ],
    [ "entretener (to entertain, amuse)", "entretener" ],
    [ "admirar (to admire, respect, look up to)", "admirar" ],
    [ "emborrachar (to make drunk)", "emborrachar" ],
    [ "emborracharse (to get drunk, become drunk)", "emborracharse" ],
    [ "pertenecer (to belong, pertain)", "pertenecer" ],
    [ "decidir (to decide, settle, resolve)", "decidir" ],
    [ "decidirse (to decide, make up one's mind)", "decidirse" ],
    [ "desaparecer (to disappear)", "desaparecer" ],
    [ "criar (to raise)", "criar" ],
    [ "criarse (to grow [up], to be brought up)", "criarse" ],
    [ "comprar (to buy, purchase)", "comprar" ],
    [ "vivir (to live)", "vivir" ],
    [ "ahorrar (to save)", "ahorrar" ],
    [ "teñir (to dye, tint, tinge)", "teñir" ],
    [ "atreverse (to dare)", "atreverse" ],
    [ "soñar (to dream)", "soñar" ],
    [ "cenar (to eat supper)", "cenar" ],
    [ "extender (to extend)", "extender" ],
    [ "juntarse (to join)", "juntarse" ],
    [ "apostar (to bet, wager)", "apostar" ],
    [ "pagar (to pay, pay for)", "pagar" ],
    [ "amar (to love)", "amar" ],
    [ "patinar (to skate, to slide, skid)", "patinar" ],
    [ "caracterizar (to characterize)", "caracterizar" ],
    [ "afirmar (to make firm)", "afirmar" ],
    [ "apoyar (to support, hold up, prop up)", "apoyar" ],
    [ "medir (to measure, be)", "medir" ],
    [ "necesitar (to need, require)", "necesitar" ],
    [ "manejar (to drive)", "manejar" ],
    [ "tocar (to touch, to play)", "tocar" ],
    [ "jugar (to play)", "jugar" ],
    [ "acompañar (to accompany, go with, escort)", "acompañar" ],
    [ "saltar (to jump, leap, to jump over)", "saltar" ],
    [ "sentar (to seat, sit)", "sentar" ],
    [ "sentarse (to sit down, seat oneself)", "sentarse" ],
    [ "participar (to participate)", "participar" ],
    [ "variar (to vary)", "variar" ],
    [ "plantar (to plant)", "plantar" ],
    [ "oír (to hear)", "oír" ],
    [ "ladrar (to bark)", "ladrar" ],
    [ "regular (to regulate, adjust, control)", "regular" ],
    [ "prepararse (to get)", "prepararse" ],
    [ "volar (to fly)", "volar" ],
    [ "obligar (to oblige)", "obligar" ],
    [ "contaminar (to contaminate)", "contaminar" ],
    [ "casar (to marry)", "casar" ],
    [ "casarse (to marry [someone])", "casarse" ],
    [ "preparar (to prepare, get)", "preparar" ],
    [ "juntar (to join, unite, put together)", "juntar" ],
    [ "llamarse (to be called, be named)", "llamarse" ],
    [ "explotar (to exploit, to explode)", "explotar" ],
    [ "atraer (to attract, draw, lure)", "atraer" ],
    [ "apagar (to extinguish)", "apagar" ],
    [ "herir (to injure, hurt, wound)", "herir" ],
    [ "indicar (to indicate)", "indicar" ],
    [ "comprender (to understand, comprehend)", "comprender" ],
    [ "llenar (to fill, to fulfill)", "llenar" ],
    [ "formar (to form, shape, fashion)", "formar" ],
    [ "emigrar (to emigrate, to migrate)", "emigrar" ],
    [ "devorar (to devour, eat up, gobble up)", "devorar" ],
    [ "culpar (to blame, accuse, to condemn)", "culpar" ],
    [ "consumir (to consume, eat, use upe)", "consumir" ],
    [ "reparar (to repair, mend, restore)", "reparar" ],
    [ "asustar (to frighten, scare, startle)", "asustar" ],
    [ "asustarse (to be frightened [oneself])", "asustarse" ],
    [ "limpiar (to clean)", "limpiar" ],
    [ "entrar (to enter, go in, come in)", "entrar" ],
    [ "suceder (to happen, to succeed, follow)", "suceder" ],
    [ "equivocar (to mistake)", "equivocar" ],
    [ "equivocarse (to make a mistake)", "equivocarse" ],
    [ "montar (to mount, to get on, to ride)", "montar" ],
    [ "calentar (to heat [up], warm [up])", "calentar" ],
    [ "calentarse (to heat up, warm up)", "calentarse" ],
    [ "publicar (to publish)", "publicar" ],
    [ "bendecir (to bless, foretell, forecast)", "bendecir" ],
    [ "entender (to understand)", "entender" ],
    [ "responder (to respond, answer, reply to)", "responder" ],
    [ "desear (to desire, want, wish)", "desear" ],
    [ "pelear (to fight, to brawl, scruffle)", "pelear" ],
    [ "enriquecer (to enrich, make rich)", "enriquecer" ],
    [ "enriquecerse (to get rich)", "enriquecerse" ],
    [ "abordar (to board, get on [plane, bus])", "abordar" ],
    [ "realizar (to achieve, attain, accomplish)", "realizar" ],
    [ "negociar (to negotiate)", "negociar" ],
    [ "conseguir (to get, obtain)", "conseguir" ],
    [ "notar (to note, notice, observe)", "notar" ],
    [ "diseñar (to design)", "diseñar" ],
    [ "bordar (to embroider)", "bordar" ],
    [ "interpretar (to interpret, to translate)", "interpretar" ],
    [ "encontrar (to find, encounter)", "encontrar" ],
    [ "eliminar (to eliminate)", "eliminar" ],
    [ "avisar (to warn, inform, notify)", "avisar" ],
    [ "platicar (to chat, talk)", "platicar" ],
    [ "obedecer (to obey)", "obedecer" ],
    [ "colgar (to hang, hang up, be hanging)", "colgar" ],
    [ "consistir (to consist [of])", "consistir" ],
    [ "pesar (to weigh)", "pesar" ],
    [ "adornar (to adorn)", "adornar" ],
    [ "entrevistar (to interview)", "entrevistar" ],
    [ "faltar (to lack)", "faltar" ],
    [ "aprobar (to pass)", "aprobar" ],
    [ "cocinar (to cook, to do the cooking)", "cocinar" ],
    [ "sacudir (to shake, beat, flap)", "sacudir" ],
    [ "señalar (to point out)", "señalar" ],
    [ "esperar (to hope)", "esperar" ],
    [ "convertir (to convert)", "convertir" ],
    [ "huir (to flee, run away)", "huir" ],
    [ "firmar (to sign)", "firmar" ],
    [ "venir (to come)", "venir" ],
    [ "ejercer (to exercise)", "ejercer" ],
    [ "bajar (to lower, go down, descend)", "bajar" ],
    [ "influir (to influence)", "influir" ],
    [ "depositar (to deposit)", "depositar" ],
    [ "utilizar (to use, utilize)", "utilizar" ],
    [ "encender (to burn, light, turn on)", "encender" ],
    [ "alegrar (to make happy)", "alegrar" ],
    [ "alegrarse (to be glad)", "alegrarse" ],
    [ "vaciar (to empty)", "vaciar" ],
    [ "prever (to foresee)", "prever" ],
    [ "admitir (to admit, accept, allow)", "admitir" ],
    [ "situar (to place, put, situate)", "situar" ],
    [ "renovar (to renew, to renovate)", "renovar" ],
    [ "nadar (to swim)", "nadar" ],
    [ "oler (to smell)", "oler" ],
    [ "felicitar (to congratulate)", "felicitar" ],
    [ "llamar (to call, to name)", "llamar" ],
    [ "aspirar (to breath in)", "aspirar" ],
    [ "dedicar (to dedicate)", "dedicar" ],
    [ "dedicarse (to devote oneself)", "dedicarse" ],
    [ "condenar (to condemn, convict, sentence)", "condenar" ],
    [ "mezclar (to mix, mix up, mix together)", "mezclar" ],
    [ "ofrecer (to offer)", "ofrecer" ],
    [ "conducir (to drive, conduct)", "conducir" ],
    [ "mudarse (to move [change residence])", "mudarse" ],
    [ "nacer (to be born)", "nacer" ],
    [ "traer (to bring, to get, fetch)", "traer" ],
    [ "describir (to describe)", "describir" ],
    [ "enseñar (to teach, instruct, train)", "enseñar" ],
    [ "leer (to read)", "leer" ],
    [ "jurar (to swear)", "jurar" ],
    [ "coser (to sew, sew up, to stitch)", "coser" ],
    [ "maquillar (to put make-up on)", "maquillar" ],
    [ "maquillarse (to put on make-up [on oneself])", "maquillarse" ],
    [ "jactarse (to boast, brag)", "jactarse" ],
    [ "renacer (to be reborn)", "renacer" ],
    [ "acercar (to bring near[er])", "acercar" ],
    [ "acercarse (to approach)", "acercarse" ],
    [ "temer (to fear)", "temer" ],
    [ "asistir (to attend)", "asistir" ],
    [ "informar (to inform, tell, announce)", "informar" ],
    [ "adivinar (to guess, fortell, prophesy)", "adivinar" ],
    [ "creer (to believe)", "creer" ],
    [ "violar (to violate, to rape)", "violar" ],
    [ "atravesar (to cross)", "atravesar" ],
    [ "calmar (to calm (down), quiet, soothe)", "calmar" ],
    [ "calmarse (to calm down [oneself])", "calmarse" ],
    [ "generalizar (to generalize)", "generalizar" ],
    [ "explicar (to explain)", "explicar" ],
    [ "tratar (to treat, handle)", "tratar" ],
    [ "confesar (to confess)", "confesar" ],
    [ "tener (to have)", "tener" ],
    [ "matar (to kill, slay, slaughter)", "matar" ],
    [ "causar (to cause, to create, make)", "causar" ],
    [ "convencer (to convince)", "convencer" ],
    [ "modificar (to modify, change)", "modificar" ],
    [ "ayudar (to help)", "ayudar" ],
    [ "disminuir (to diminish, decrease)", "disminuir" ],
    [ "rezar (to pray)", "rezar" ],
    [ "parar (to stop, halt)", "parar" ],
    [ "surgir (to arise, emerge, spring up)", "surgir" ],
    [ "predecir (to predict, foretell, forecast)", "predecir" ],
    [ "aguantar (to put up with, endure, bear)", "aguantar" ],
    [ "vomitar (to vomit, throw up, bring up)", "vomitar" ],
    [ "chocar (to shock)", "chocar" ],
    [ "bañar (to give a bath)", "bañar" ],
    [ "bañarse (to take a bath)", "bañarse" ],
    [ "gruñir (to grunt, growl)", "gruñir" ],
    [ "avanzar (to advance, move forward)", "avanzar" ],
    [ "requerir (to require, need, to request)", "requerir" ],
    [ "controlar (to control, to inspect, check)", "controlar" ],
    [ "lanzar (to throw, hurl, cast)", "lanzar" ],
    [ "construir (to build, construct)", "construir" ],
    [ "masticar (to chew)", "masticar" ],
    [ "quemar (to burn [up])", "quemar" ],
    [ "quemarse (to burn [oneself])", "quemarse" ],
    [ "escoger (to choose, select, pick)", "escoger" ],
    [ "alentar (to encourage, cheer, inspire)", "alentar" ],
    [ "agradar (to please, be pleasing)", "agradar" ],
    [ "coger (to catch, grasp, take hold of)", "coger" ],
    [ "preguntar (to ask, inquire)", "preguntar" ],
    [ "preguntarse (to wonder, ask oneself)", "preguntarse" ],
    [ "organizar (to organize)", "organizar" ],
    [ "proponer (to propose)", "proponer" ],
    [ "efectuar (to effect)", "efectuar" ],
    [ "guardar (to guard, watch over, protect)", "guardar" ],
    [ "elegir (to elect, choose)", "elegir" ],
    [ "desarrollar (to develop, expand, to unroll)", "desarrollar" ],
    [ "desarrollarse (to develop, evolve, to unwind)", "desarrollarse" ],
    [ "sustituir (to substitute, replace)", "sustituir" ],
    [ "evitar (to avoid, to prevent)", "evitar" ],
    [ "sobrevivir (to survive, outlive, outlast)", "sobrevivir" ],
    [ "brillar (to shine, sparkle, glitter)", "brillar" ],
    [ "perseguir (to pursue, chase, hunt down)", "perseguir" ],
    [ "subir (to go up, rise, move up)", "subir" ],
    [ "vender (to sell)", "vender" ],
    [ "picar (to prick, puncture, perforate)", "picar" ],
    [ "peinar (to comb)", "peinar" ],
    [ "peinarse (to comb one's hair)", "peinarse" ],
    [ "curar (to cure)", "curar" ],
    [ "entregar (to deliver, hand over)", "entregar" ],
    [ "acampar (to camp, encamp, go camping)", "acampar" ],
    [ "tomar (to take, drink)", "tomar" ],
    [ "aliviar (to alleviate, ease, lessen)", "aliviar" ],
    [ "mover (to move)", "mover" ],
    [ "convenir (to agree)", "convenir" ],
    [ "emplear (to employ, hire, to use)", "emplear" ],
    [ "significar (to signify, mean)", "significar" ],
    [ "confirmar (to confirm)", "confirmar" ],
    [ "rehusar (to refuse)", "rehusar" ],
    [ "ponerse (to put on [clothing])", "ponerse" ],
    [ "combatir (to combat, fight, oppose)", "combatir" ],
    [ "arrojar (to throw, hurl, cast)", "arrojar" ],
    [ "echar (to throw, cast, fling)", "echar" ],
    [ "echarse (to throw)", "echarse" ],
    [ "competir (to compete)", "competir" ],
    [ "abortar (to abort)", "abortar" ],
    [ "comunicar (to communicate, transmit, tell)", "comunicar" ],
    [ "comunicarse (to communicate)", "comunicarse" ],
    [ "dibujar (to draw, sketch, depict)", "dibujar" ],
    [ "saludar (to greet, salute, hail)", "saludar" ],
    [ "hallarse (to be)", "hallarse" ],
    [ "devolver (to return, give back)", "devolver" ],
    [ "replicar (to reply, answer back)", "replicar" ],
    [ "valer (to be worth)", "valer" ],
    [ "llevar (to carry, bring)", "llevar" ],
    [ "aumentar (to increase, add to, augment)", "aumentar" ],
    [ "apreciar (to appreciate, value, esteem)", "apreciar" ],
    [ "fijar (to fix, fasten, secure)", "fijar" ],
    [ "enojar (to anger, upset, annoy)", "enojar" ],
    [ "enojarse (to get angry)", "enojarse" ],
    [ "inventar (to invent)", "inventar" ],
    [ "presentar (to introduce)", "presentar" ],
    [ "afeitarse (to shave [oneself])", "afeitarse" ],
    [ "tirar (to throw, hurl, to shoot)", "tirar" ],
    [ "revelar (to reveal)", "revelar" ],
    [ "demostrar (to demonstrate, show)", "demostrar" ],
    [ "buscar (to search for, look for)", "buscar" ],
    [ "suponer (to suppose)", "suponer" ],
    [ "rogar (to plead, beg, ask for)", "rogar" ],
    [ "prohibir (to prohibit, forbid)", "prohibir" ],
    [ "quedar (to stay, remain, be left)", "quedar" ],
    [ "crear (to create, to make)", "crear" ],
    [ "corregir (to correct)", "corregir" ],
    [ "conservar (to preserve, conserve, to keep)", "conservar" ],
    [ "arrepentirse (to repent)", "arrepentirse" ],
    [ "pasar (to pass, pass by, go)", "pasar" ],
    [ "comer (to eat)", "comer" ],
    [ "cuidar (to take care of, look after)", "cuidar" ],
    [ "imprimir (to print)", "imprimir" ],
    [ "acostumbrar (to be accustomed to)", "acostumbrar" ],
    [ "acostumbrarse (to get used to)", "acostumbrarse" ],
    [ "averiguar (to find out, discover)", "averiguar" ],
    [ "amanecer (to dawn)", "amanecer" ],
    [ "traducir (to translate)", "traducir" ],
    [ "poner (to put, place, set)", "poner" ],
    [ "asociar (to associate)", "asociar" ],
    [ "doler (to hurt, pain, ache)", "doler" ],
    [ "salir (to leave, go out)", "salir" ],
    [ "esconder (to hide, conceal)", "esconder" ],
    [ "esconderse (to hide [oneself], be hidden)", "esconderse" ],
    [ "alcanzar (to reach, catch, catch up to)", "alcanzar" ],
    [ "acortar (to shorten)", "acortar" ],
    [ "pedir (to request, ask for)", "pedir" ],
    [ "aburrir (to bore, to tire, weary)", "aburrir" ],
    [ "aburrirse (to get bored)", "aburrirse" ],
    [ "lograr (to get, obtain, to achieve)", "lograr" ],
    [ "entusiasmar (to excite)", "entusiasmar" ],
    [ "entusiasmarse (to get excited)", "entusiasmarse" ],
    [ "alquilar (to rent, to rent out, let)", "alquilar" ],
    [ "dudar (to doubt)", "dudar" ],
    [ "permitir (to permit, allow)", "permitir" ],
    [ "morir (to die)", "morir" ],
    [ "envejecer (to age, make old, grow old)", "envejecer" ],
    [ "envejecerse (to get old, age)", "envejecerse" ],
    [ "poder (to be able, can)", "poder" ],
    [ "descansar (to rest)", "descansar" ],
    [ "cesar (to cease, stop)", "cesar" ],
    [ "anhelar (to be eager)", "anhelar" ],
    [ "cubrir (to cover)", "cubrir" ],
    [ "caber (to fit)", "caber" ],
    [ "caminar (to walk, go)", "caminar" ],
    [ "durar (to last, go on, continue)", "durar" ],
    [ "perdonar (to forgive, pardon, excuse)", "perdonar" ],
    [ "sorprender (to surprise)", "sorprender" ],
    [ "recordar (to remember, remind)", "recordar" ],
    [ "tardar (to delay)", "tardar" ],
    [ "distinguir (to distinguish)", "distinguir" ],
    [ "preservar (to preserve, protect)", "preservar" ],
    [ "llover (to rain)", "llover" ],
    [ "contener (to contain, hold)", "contener" ],
    [ "zambullirse (to dive, plunge)", "zambullirse" ],
    [ "luchar (to fight, struggle, to wrestle)", "luchar" ],
    [ "sentirse (to feel)", "sentirse" ],
    [ "madurar (to mature, to ripen)", "madurar" ],
    [ "detestar (to detest, hate)", "detestar" ],
    [ "doblar (to double)", "doblar" ],
    [ "chismear (to gossip)", "chismear" ],
    [ "ganar (to win, gain, earn)", "ganar" ],
    [ "lavar (to wash)", "lavar" ],
    [ "lavarse (to wash [oneself], to wash up)", "lavarse" ],
    [ "lastimar (to hurt, injure, harm)", "lastimar" ],
    [ "enfermar (to make ill)", "enfermar" ],
    [ "enfermarse (to get sick)", "enfermarse" ],
    [ "fingir (to fake, feign, pretend)", "fingir" ],
    [ "conversar (to talk, converse, to tell)", "conversar" ],
    [ "descubrir (to discover)", "descubrir" ],
    [ "continuar (to continue)", "continuar" ],
    [ "gritar (to shout, yell, scream)", "gritar" ],
    [ "helar (to freeze)", "helar" ],
    [ "tañer (to play)", "tañer" ],
    [ "reinar (to reign, rule)", "reinar" ],
    [ "funcionar (to function, to run, work)", "funcionar" ],
    [ "toser (to cough)", "toser" ],
    [ "cobrar (to charge [a price])", "cobrar" ],
    [ "insistir (to insist)", "insistir" ],
    [ "matricular (to register)", "matricular" ],
    [ "matricularse (to register)", "matricularse" ],
    [ "freír (to fry)", "freír" ],
    [ "almorzar (to lunch)", "almorzar" ],
    [ "acostar (to put to bed)", "acostar" ],
    [ "acostarse (to go to bed, lie down)", "acostarse" ],
    [ "quejarse (to complain)", "quejarse" ],
    [ "copiar (to copy)", "copiar" ],
    [ "hablar (to speak)", "hablar" ],
    [ "recoger (to pick up)", "recoger" ],
    [ "aplicar (to apply)", "aplicar" ],
    [ "estudiar (to study)", "estudiar" ],
    [ "apretar (to be too tight)", "apretar" ],
    [ "caer (to fall)", "caer" ],
    [ "verificar (to verify, check, inspect)", "verificar" ],
    [ "compartir (to share, to divide [up])", "compartir" ],
    [ "salvar (to save, rescue, to except)", "salvar" ],
    [ "detener (to stop, to hold up, delay)", "detener" ],
    [ "detenerse (to stop, pause, linger)", "detenerse" ],
    [ "seguir (to follow, continue)", "seguir" ],
    [ "marcar (to mark, stamp, to show)", "marcar" ],
    [ "clarificar (to clarify)", "clarificar" ],
    [ "dar (to give)", "dar" ],
    [ "arreglar (to arrange, settle, fix up)", "arreglar" ],
    [ "guiar (to guide)", "guiar" ],
    [ "intentar (to try, attempt)", "intentar" ],
    [ "bucear (to skin-dive)", "bucear" ],
    [ "soportar (to support, hold up, bear)", "soportar" ],
    [ "duchar (to douche)", "duchar" ],
    [ "ducharse (to take a shower)", "ducharse" ],
    [ "afligir (to afflict, to grieve, to pain)", "afligir" ],
    [ "perder (to lose)", "perder" ],
    [ "encantar (to delight)", "encantar" ],
    [ "sonar (to sound, to ring)", "sonar" ],
    [ "terminar (to finish, end)", "terminar" ],
    [ "escribir (to write)", "escribir" ],
    [ "regar (to water, irrigate, wash)", "regar" ],
    [ "sentir (to feel, regret)", "sentir" ],
    [ "robar (to rob, steal, burgle)", "robar" ],
    [ "afeitar (to shave)", "afeitar" ],
    [ "sacar (to take out, stick out)", "sacar" ],
    [ "cruzar (to cross)", "cruzar" ],
    [ "iniciar (to initiate, begin, start)", "iniciar" ],
    [ "tragar (to swallow)", "tragar" ],
    [ "mentir (to lie)", "mentir" ],
    [ "respirar (to breathe, breathe in, inhale)", "respirar" ],
    [ "cumplir (to fulfil)", "cumplir" ],
    [ "invertir (to invest)", "invertir" ],
    [ "actuar (to act, perform, actuate)", "actuar" ],
    [ "recibir (to receive, to welcome, greet)", "recibir" ],
    [ "mirar (to watch, look at)", "mirar" ],
    [ "distribuir (to distribute)", "distribuir" ],
    [ "añadir (to add, to increase)", "añadir" ],
    [ "legalizar (to legalize, make lawful)", "legalizar" ],
    [ "depender (to depend)", "depender" ],
    [ "volver (to return, go back)", "volver" ],
    [ "decir (to say, tell)", "decir" ],
    [ "conquistar (to conquer, overcome, win)", "conquistar" ],
    [ "atender (to attend to, pay attention to)", "atender" ],
    [ "molestar (to bother)", "molestar" ],
    [ "dirigir (to direct)", "dirigir" ],
    [ "cazar (to hunt, represent, stand for)", "cazar" ],
    [ "agradecer (to be thankful for)", "agradecer" ],
    [ "invocar (to invoke)", "invocar" ],
    [ "saber (to know)", "saber" ],
    [ "practicar (to practice)", "practicar" ],
    [ "reír (to laugh)", "reír" ],
    [ "criticar (to criticize)", "criticar" ],
    [ "heredar (to inherit)", "heredar" ],
    [ "bautizar (to baptize, christen)", "bautizar" ],
    [ "invadir (to invade, overrun)", "invadir" ],
    [ "vencer (to conquer, defeat, beat)", "vencer" ],
    [ "repetir (to repeat)", "repetir" ],
    [ "purificar (to purify)", "purificar" ],
    [ "deber (to owe, must, should)", "deber" ],
    [ "padecer (to suffer)", "padecer" ],
    [ "obtener (to obtain, get)", "obtener" ],
    [ "contestar (to answer)", "contestar" ],
    [ "exhibir (to exhibit, show, display)", "exhibir" ],
    [ "sacrificar (to sacrifice)", "sacrificar" ],
    [ "ofender (to offend)", "ofender" ],
    [ "glorificar (to glorify, praise)", "glorificar" ],
    [ "conocer (to know)", "conocer" ],
    [ "trabajar (to work)", "trabajar" ],
    [ "cortar (to cut)", "cortar" ],
    [ "instalar (to install, to set up, erect)", "instalar" ],
    [ "amenazar (to threaten, menace)", "amenazar" ],
    [ "pescar (to fish)", "pescar" ],
    [ "besar (to kiss)", "besar" ],
    [ "preferir (to prefer)", "preferir" ],
    [ "abrir (to open)", "abrir" ],
    [ "escuchar (to listen to, hear)", "escuchar" ],
    [ "simbolizar (to symbolize)", "simbolizar" ],
    [ "hallar (to find)", "hallar" ],
    [ "interesar (to interest)", "interesar" ],
    [ "untar (to anoint, to smear, rub)", "untar" ],
    [ "aplaudir (to applaud, cheer, clap)", "aplaudir" ],
    [ "viajar (to travel, journey)", "viajar" ],
    [ "fortalecer (to fortify, strenthen)", "fortalecer" ],
    [ "registrar (to register, record, to search)", "registrar" ],
    [ "registrarse (to register [oneself])", "registrarse" ],
    [ "explorar (to explore, to pioneer)", "explorar" ],
    [ "borrar (to erase)", "borrar" ],
    [ "acabar (to finish, end)", "acabar" ],
    [ "confiscar (to confiscate)", "confiscar" ],
    [ "recomendar (to recommend)", "recomendar" ],
    [ "divorciar (to divorce)", "divorciar" ],
    [ "divorciarse (to get divorced [from somebody])", "divorciarse" ],
    [ "estimar (to estimate)", "estimar" ],
    [ "cancelar (to cancel)", "cancelar" ],
    [ "visitar (to visit)", "visitar" ],
    [ "enflaquecer (to make thin)", "enflaquecer" ],
    [ "enflaquecerse (to get thin)", "enflaquecerse" ],
    [ "contar (to count, relate, tell)", "contar" ],
    [ "probar (to taste, try, test)", "probar" ],
    [ "estar (to be)", "estar" ],
    [ "adorar (to adore, worship)", "adorar" ],
    [ "olvidar (to forget)", "olvidar" ],
    [ "olvidarse (to forget)", "olvidarse" ],
    [ "cargar (to load, load up, to charge)", "cargar" ],
    [ "reciclar (to recycle)", "reciclar" ],
    [ "regalar (to give [as a gift])", "regalar" ],
    [ "cultivar (to cultivate, to grow)", "cultivar" ],
    [ "navegar (to navigate, sail)", "navegar" ],
    [ "evacuar (to evacuate, empty)", "evacuar" ],
    [ "fumar (to smoke)", "fumar" ],
    [ "soler (to be accustomed to)", "soler" ],
    [ "disfrutar (to enjoy)", "disfrutar" ],
    [ "expresar (to express, voice, state)", "expresar" ],
    [ "celebrar (to celebrate)", "celebrar" ],
    [ "insultar (to insult)", "insultar" ],
    [ "vestirse (to get dressed, dress)", "vestirse" ],
    [ "crucificar (to crucify)", "crucificar" ],
    [ "respetar (to respect)", "respetar" ],
    [ "anunciar (to announce)", "anunciar" ],
    [ "producir (to produce)", "producir" ],
    [ "censurar (to censor)", "censurar" ],
    [ "decorar (to decorate, adorn)", "decorar" ],
    [ "reñir (to quarrel, fight, to scold)", "reñir" ],
    [ "hervir (to boil, entertain)", "hervir" ],
    [ "defender (to defend)", "defender" ],
    [ "levantar (to raise, to lift)", "levantar" ],
    [ "levantarse (to get up)", "levantarse" ],
    [ "sugerir (to suggest)", "sugerir" ],
    [ "civilizar (to civilize)", "civilizar" ],
    [ "incluir (to include)", "incluir" ],
    [ "mantener (to maintain, get)", "mantener" ],
    [ "pegar (to stick, glue, hit)", "pegar" ],
    [ "gustar (to please, be pleasing)", "gustar" ],
    [ "nevar (to snow)", "nevar" ],
    [ "charlar (to chat, talk)", "charlar" ],
    [ "lamentar (to lament)", "lamentar" ],
    [ "reconocer (to recognize)", "reconocer" ],
    [ "castigar (to punish, penalize, castigate)", "castigar" ],
    [ "atacar (to attack)", "atacar" ],
    [ "provocar (to provoke)", "provocar" ],
    [ "aparecer (to appear)", "aparecer" ],
    [ "reducir (to reduce)", "reducir" ],
    [ "ocurrir (to occur, happen)", "ocurrir" ],
    [ "fascinar (to fascinate, captivate)", "fascinar" ],
    [ "ver (to see)", "ver" ],
    [ "ahorcar (to hang)", "ahorcar" ],
    [ "contribuir (to contribute)", "contribuir" ],
    [ "exponer (to expose)", "exponer" ],
    [ "inducir (to induce)", "inducir" ],
    [ "proseguir (to continue, proceed with)", "proseguir" ],
    [ "ensuciar (to dirty)", "ensuciar" ],
    [ "merecer (to deserve, merit)", "merecer" ],
]

export { verbData };
import React from "react";
import { Tab, SettingsPanelTabsProps } from "./types.d";

function SettingsPanelTabs(props: SettingsPanelTabsProps) {
  function activeClass(activeTab: Tab) {
    return props.activeTab === activeTab ? "is-active" : "";
  }

  function setTab(newTab: Tab) {
    props.setActiveTab(newTab);
    props.updateParent({});
  }

  return (
    <p className="panel-tabs">
      <a href="#verbs" className={activeClass("verbs")} onClick={() => setTab("verbs")}><span className="verb-tab">Verbs</span></a>
      <a href="#tenses" className={activeClass("tenses")} onClick={() => setTab("tenses")}>Tenses</a>
      <a href="#pronouns" className={activeClass("pronouns")} onClick={() => setTab("pronouns")}>Pronouns</a>
    </p>
  );
}

export default SettingsPanelTabs;

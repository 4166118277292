import React from "react";
import { AudioToggleProps } from "./types.d";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVolumeUp,
  faVolumeMute,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";

const AudioToggle = (props: AudioToggleProps) => {
  function audioIcon(): IconDefinition {
    return props.activeAudio ? faVolumeUp : faVolumeMute;
  }

  function toggleAudio() {
    props.setActiveAudio((active) => !active);
  }

  return (
    <div className="navbar-item">
      <FontAwesomeIcon className="audio-icon" icon={audioIcon()} size="lg" onClick={toggleAudio} />
    </div>
  );
};

export default AudioToggle;
import React from "react";
import { SettingsPanelItemProps } from "./types";
import { getActiveForTab } from "./SettingsPanelUtil";
import { tenseTooltips } from "./SettingsPanelTenseData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";


function SettingsPanelItem(props: SettingsPanelItemProps) {
  const [activeList, setActiveList] = getActiveForTab(props);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const target = e.target;

    if (target.checked) {
      setActiveList((state) => state.add(props.value));
      console.log(activeList);
    } else {
      activeList.delete(props.value);
      setActiveList(activeList);
    }

    if (props.updateParent) {
      props.updateParent({});
    }
  }

  return (
    <span className="panel-block">
      <input
        type="checkbox"
        onChange={handleChange}
        checked={activeList.has(props.value)}
      />
      {props.label}

      {tenseTooltips[props.value] && (
        <span className="ml-2 has-tooltip-arrow" data-html={true} data-tip={tenseTooltips[props.value]}>
          <FontAwesomeIcon icon={faInfoCircle} />
        </span>
      )}
    </span>
  );
}

export default SettingsPanelItem;

import { PRONOUN_KEYS, PronounKey, TENSE_KEYS, TenseKey } from "./types.d";

export function isPronounKey(key: string): key is PronounKey {
    return PRONOUN_KEYS.includes(key as PronounKey);
}

export function isTenseKey(key: string): key is TenseKey {
    return TENSE_KEYS.includes(key as TenseKey);
}

export function pctCorrect(correct: number, total: number): string {
    const pct = (total === 0) ? 0 : Math.floor((correct / total)*100);
    return "%" + pct.toString();
}

export function fmtTense(tense: string) {
    return tense.replace(new RegExp('_', 'g'), " ");
}

export const pronounOrder: [PronounKey, PronounKey][] = [
    ["yo", "I"],
    ["tú", "You"],
    ["él/ella/usted", "She"],
    ["nosotros/nosotras", "We"],
    ["vosotros/vosotras", "Y'all"],
    ["ellos/ellas/ustedes", "They"],
];

export const tenseOrder: string[] = [
    'indicative_present',
    'indicative_preterite',
    'indicative_imperfect',
    'indicative_conditional',
    'indicative_future',
    'subjunctive_present',
    'subjunctive_imperfect',
    'indicative_perfect_present',
    'indicative_perfect_preterite',
    'indicative_perfect_past',
    'indicative_perfect_conditional',
    'indicative_perfect_future',
    'imperative_negative',
    'imperative_affirmative',
    'subjunctive_perfect_present',
    'subjunctive_perfect_past',
    'subjunctive_perfect_future',
    'continuous_present',
    'continuous_preterite',
    'continuous_imperfect',
    'continuous_conditional',
    'continuous_future',
    'subjunctive_future',
];

import React from "react";
import { ExampleProps } from "./types.d";

const Examples = (props: ExampleProps) => {
  return (
    <div className="column ml2">
      <h3 className="title is-4">Examples:</h3>
      <ol className="ml">
        {props.phrases.map((phrase, index) => {
          return (
            <div key={index}>
              <p>{phrase.es}</p>
              <p className="has-text-grey-light">{phrase.en}</p>
            </div>
          );
        })}
      </ol>
    </div>
  );
};

export default Examples;
import React, { useState, useEffect } from "react";
import { CallBackProps, Step, STATUS } from "react-joyride";
import { TourButtonProps } from "./types.d";

const tourBegin = (
  <div>
    <h1><b>Welcome to Verbosely!</b></h1>
    <br></br>
    <p>
      Vebosely is a language learning platform designed to help students of all
      levels master Spanish verb conjugation. This tour will take you through
      the basic parts of the app.
    </p>
  </div>
);

const tourInput = (
  <div>
    <h1><b>Questions &amp; Answers</b></h1>
    <br />
    <p>
      Verbosely will put an English phrase into this box.
      Your goal is to type the corresponding Spanish phrase into the same box.
    </p><br />
    <p>
      When you respond, <i>do not</i> include the Spanish pronoun (yo, tú, él, etc).
    </p>
    <br />
    <h1><b>Example</b></h1><br />
    <p>Prompt: <i>I speak (indicative present)</i></p>
    <br />
    <p>Response: <i>hablo</i></p>
  </div>
);

const tourTags = (
    <div>
        <h1><b>Verb &amp; Pronoun</b></h1>
        <br />
        <p>The active verb and pronoun are displayed here.</p>
    </div>
);

const tourChoice = (
  <div>
    <h1><b>Multiple Choice</b></h1>
    <br />
    <p>
      If you are uncertain about an answer, or you just don't feel like
      typing, you can use this button to pick from a selection of pre-defined choices.
    </p>
  </div>
);

const tourMainSubmit = (
  <div>
    <h1><b>Submit Your Answer</b></h1>
    <br />
    <p>
      When you are done typing your answer, you can submit it with this
      button, or you can just press Enter on your keyboard.
    </p>
  </div>
);

const tourAutoplay = (
  <div>
    <h1><b>Autoplay</b></h1>
    <br />
    <p>
      Autoplay will automatically step through the currently selected
      conjugations—saying each phrase out loud. This feature is designed for
      listening practice and is a great way to familiarize yourself with
      unfamiliar verb forms and pronunciations.
    </p>
  </div>
);

const tourRepeat = (
    <div>
        <h1><b>Say It</b></h1>
        <br />
        <p>
            If you missed a spoken phrase the first time, or you'd like to
            hear it again, the "Say It" button will say the currently active
            phrase out loud.
        </p>
    </div>
);

const tourAudio = (
    <div>
        <h1><b>Mute &amp; Unmute</b></h1>
        <br />
        <p>
            You can toggle whether audio is enabled by clicking on this icon.
        </p>
    </div>
);

const tourAccents = (
    <div>
        <h1><b>Accents</b></h1>
        <br />
        <p>Typing accents can be tricky, so Verbosely gives you a few
           convenient ways to enter them.</p><br></br>
        <p>1. Clicking on any of the buttons
           in the accent row will append its corresponding accent to your
           current answer.</p><br />
        <p>2. Typing in a capital "A", "E", "I", "O", or "U" will
           will enter the accented lowercase version of that letter.</p>
    </div>
);

const tourSettings = (
    <div>
        <h1><b>Settings Panel</b></h1>
        <br />
        <p>Use the settings panel to select your verbs, tenses, and pronouns.
            The interactive search can quickly find verbs in both English and Spanish.</p><br />
        <p>A quick reference for each tense is provided on the tenses tab.</p><br />
        <p>Verbosely currently provides conjugations for 636 verbs
           with over 138,000 verb, tense, and pronoun combinations—each of which
           includes a spoken pronunciation!</p><br />
        <p>¡Esperamos que lo disfrutes!</p>
    </div>
)

export const tourSteps: Step[] = [
  {
    target: "body",
    placement: "center",
    content: tourBegin,
    disableOverlay: true,
    disableBeacon: true,
  },
  {
    target: ".input",
    content: tourInput,
    placement: "left",
    disableOverlay: true,
    disableBeacon: true,
  },
  {
    target: ".verb-pronoun",
    content: tourTags,
    placement: "left",
    spotlightPadding: 1,
    disableOverlay: true,
    disableBeacon: true,
  },
  {
    target: ".multiple-choice",
    content: tourChoice,
    disableBeacon: true,
    disableOverlay: true,
    spotlightPadding: 3,
  },
  {
    target: ".main-submit",
    content: tourMainSubmit,
    spotlightPadding: 3,
    disableOverlay: true,
    disableBeacon: true,
  },
  {
    target: ".autoplay",
    content: tourAutoplay,
    spotlightPadding: 3,
    disableOverlay: true,
    disableBeacon: true,
  },
  {
    target: ".repeat",
    content: tourRepeat,
    spotlightPadding: 3,
    disableOverlay: true,
    disableBeacon: true,
  },
  {
    target: ".audio-icon",
    content: tourAudio,
    spotlightPadding: 3,
    disableOverlay: true,
    disableBeacon: true,
  },
  {
    target: ".accents",
    content: tourAccents,
    placement: "left",
    spotlightPadding: 1,
    disableOverlay: true,
    disableBeacon: true,
  },
  {
    target: ".Content",
    placement: "left",
    content: tourSettings,
    spotlightPadding: 1,
    disableOverlay: true,
    disableBeacon: true,
  },

];

export const handleJoyrideCallback = (
  setRunTour: React.Dispatch<React.SetStateAction<boolean>>
) => (data: CallBackProps) => {
  const { status } = data;
  const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

  if (finishedStatuses.includes(status)) {
    setRunTour(false);
  }
};

export const TourButton = (props: TourButtonProps) => {
  const [buttonKey, setButtonKey] = useState(0);
  const [intervalID, setIntervalID] = useState(0);

  useEffect(() => {
    const interval = window.setInterval(() => {
      setButtonKey((k) => k + 1);
    }, 30000);
    setIntervalID(interval);
    return () => clearInterval(interval);
  }, []);

  const runTour = () => {
    props.setRunTour(true);
    clearInterval(intervalID);
    // activate tour passed in via prop
  };

  return (
    <button
      key={buttonKey}
      onClick={runTour}
      className="button is-small is-success waggle"
    >
      Tour
    </button>
  );
};

export default TourButton;

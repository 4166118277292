import React, { useRef } from "react";
import { SettingsPanelSearchProps } from "./types.d";

function SettingsPanelSearch(props: SettingsPanelSearchProps) {
  const textInput = useRef<HTMLInputElement>(null);

  function reset() {
    const origData = props.tabData[props.activeTab];
    const filtered = props.tabDataFiltered;
    filtered[props.activeTab] = origData;
    props.setTabDataFiltered(filtered);
    props.updateParent({});
  }

  function applyFilter(filter: string) {
    if (filter === '') {
      reset();
      return;
    }

    const activeTab = props.activeTab;
    const newFiltered = props.tabData[activeTab].filter(item => {
      return item.label.toLowerCase().includes(filter.toLowerCase())
    });

    const filtered = props.tabDataFiltered;
    const verbs = (activeTab === "verbs") ? newFiltered : filtered["verbs"];
    const pronouns = (activeTab === "pronouns") ? newFiltered : filtered["pronouns"];
    const tenses = (activeTab === "tenses") ? newFiltered : filtered["tenses"];
    props.setTabDataFiltered({verbs: verbs, pronouns: pronouns, tenses: tenses});
  }

  function keyPress(e: React.KeyboardEvent<HTMLInputElement>) {
    if (textInput.current?.value || textInput.current?.value === "") {
      applyFilter(textInput.current.value);
    }
  }

  return (
    <div className="panel-block">
      <p className="control has-icons-left">
        <input
          className="input"
          type="text"
          placeholder="Search"
          onKeyUp={keyPress}
          ref={textInput}
        />
        <span className="icon is-left">
          <i className="fas fa-search" aria-hidden="true"></i>
        </span>
      </p>
    </div>
  );
}

export default SettingsPanelSearch;
